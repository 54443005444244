import { getCSSVariable } from '../../NTMiniMap/NTMiniMapUtils';
import { ChartContext } from '../types';

// let's try to force lower case in the file name

export const themeColors = () => ({
  Actual: {
    line: {
      positive: getCSSVariable('--ks-chart-dark-primary-solid', 1),
      hovered: getCSSVariable('--ks-chart-primary', 0.1),
      negative: getCSSVariable('--ks-chart-dark-primary-solid-negative', 1),
    },
    fill: {
      positive: getCSSVariable('--ks-chart-primary'),
      selected: getCSSVariable('--ks-chart-dark-primary'),
      hovered: getCSSVariable('--ks-chart-dark-primary', 0.24),
      negative: getCSSVariable('--ks-chart-primary-negative'),
    },
  },
  Budget: {
    line: {
      positive: getCSSVariable('--ks-chart-dark-green-bluish-solid', 1),
      hovered: getCSSVariable('--ks-chart-green-bluish', 0.2),
      negative: getCSSVariable('--ks-chart-dark-green-bluish-solid-negative', 1),
    },
    fill: {
      positive: getCSSVariable('--ks-chart-green-bluish'),
      selected: getCSSVariable('--ks-chart-dark-green-bluish'),
      hovered: getCSSVariable('--ks-chart-dark-green-bluish', 0.46),
      negative: getCSSVariable('--ks-chart-green-bluish-negative'),
    },
  },
  Variance: {
    line: {
      positive: getCSSVariable('--ks-chart-dark-purple-solid', 1),
      hovered: getCSSVariable('--ks-chart-purple', 0.6),
      negative: getCSSVariable('--ks-chart-dark-purple-solid-negative', 1),
    },
    fill: {
      positive: getCSSVariable('--ks-chart-purple'),
      selected: getCSSVariable('--ks-chart-dark-purple'),
      hovered: getCSSVariable('--ks-chart-dark-purple', 0.3),
      negative: getCSSVariable('--ks-chart-purple-negative'),
    },
  },
});

export const saturatedPositiveColors = [
  '#D1F2A5',
  '#95E1D3',
  '#B5A8E0',
  '#FFECB8',
  '#89D2E6',
  '#E0BBE4',
  '#9FD8CB',
  '#CFE4B3',
  '#B6B4E0',
  '#98D7C2',
];

export const desaturatedPositiveColors = [
  '#C8E4A7',
  '#9CD3C8',
  '#B5B1D6',
  '#EDE3C0',
  '#96C5D4',
  '#D5BBD7',
  '#A7CCC3',
  '#C5D6B2',
  '#B6B4D2',
  '#A2C9BC',
];

export const positiveColors = [
  '#DBF0C3',
  '#B7E2D9',
  '#CBC8E4',
  '#F5EED6',
  '#B4D8E4',
  '#E4D3E5',
  '#C2DED7',
  '#D9E6CC',
  '#CFCDE5',
  '#BEDBD1',
];
/* export const negativeColors = [
  '#F2A5A5', '#D3E195', '#E0B5A8', '#E6D289', '#E6CFE4',
  '#CB9FD8', '#95D7CB', '#B3CFE4', '#B4E0B6', '#C2D798'
]; */

export const negativeColors = [
  '#FFD4D4',
  '#FFCAAF',
  '#FFB5B5',
  '#FFBFA9',
  '#FF9E9E',
  '#FFB199',
  '#FF8A8A',
  '#FFA088',
  '#FF7575',
  '#FF8E70',
];

export const chartContextDefault: ChartContext = {
  ctx: null,
  data: null,
  groups: [],
  dimensions: { width: 0, height: 0 },
  selectedPeriod: -1,
  hoveredPeriod: -1,
  allValuesAreZero: false,
  body: {
    font: 'Montserrat',
    fontSize: {
      label: 11,
      value: 12,
    },
    margin: { top: 20, right: 60, bottom: 80, left: 60 },
    groupGap: 12,
    barGap: 0,
    chartWidth: 0,
    chartHeight: 0,
    groupWidth: 0,
    barWidth: 0,
  },
  structure: {
    yMoneyScale: { min: 0, max: 0, tickSpacing: 0 },
    yPercentScale: { min: 0, max: 0, tickSpacing: 0 },
    zeroLineY: 0,
    zeroPercentLineY: 0,
    maxTicks: 5,
    useAngledLabels: false,
  },
  chartFilters: {
    groups: [],
    money: false,
    percent: false,
  },
};
