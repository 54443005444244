import { useMemo } from 'react';
import { evalWidths, computeAvailableFilters } from '../utils/sizingUtils';

export const useColumnWidths = (transformedData: any, filters: any, capabilities: any, budgetAvailable: boolean) => {
  return useMemo(() => {
    if (!transformedData) return null;
    return evalWidths(
      computeAvailableFilters(filters, capabilities, budgetAvailable),
      transformedData.multiLocation && !transformedData.consolidated
    );
  }, [transformedData.columns.length, filters, capabilities, budgetAvailable]);
};
