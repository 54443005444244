import { IconAsk, IconBookkeeper, IconDashboard, IconDashboardNew, IconUnion } from 'components/Icons';
import { useLocation } from 'react-router-dom';

interface LayoutHeaderProps {
  customItems?: JSX.Element;
  icon?: JSX.Element;
}

export default function LayoutHeader({ customItems }: LayoutHeaderProps): JSX.Element {
  const { pathname } = useLocation();

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const pageTitle = pathname.includes('bookkeeper') ? pathname.split('/')[2] : pathname.split('/')[4].replace('-', ' ');
  const beta = pageTitle.includes('forecast');

  const icons: Record<string, JSX.Element> = {
    forecast: <IconUnion />,
    dashboard: <IconDashboardNew />,
    bookkeeper: <IconBookkeeper />,
    'ask requests': <IconAsk />,
  };
  return (
    <div className="layout-view--header">
      {pageTitle !== 'report' && (
        <div className="layout-view-container-title">
          <header className="layout-title">
            <ol className="breadcrumb">
              {!isMobile && <li>{icons[pageTitle]}</li>}
              <li>{pageTitle}</li>
              {beta && (
                <li className="beta">
                  <p>Beta</p>
                </li>
              )}
            </ol>
          </header>
        </div>
      )}

      <div>{customItems ? customItems : null}</div>
    </div>
  );
}
