import { prefix } from 'rsuite/esm/internals/utils';
import { TooltipDataProps } from '../../types';
import './ChartTooltip.scss';
import { prefixClass } from 'lib/utils';
import {
  formatAmountCondensed,
  formatAmount,
} from 'presentation/components/NewTable/NTCell/ValueFormatters/ValueFormatters';
import classNames from 'classnames';

const ChartTooltip = ({ zone }: { zone: TooltipDataProps | null }) => {
  if (!zone) return null;

  const prefix = prefixClass('inner-chart-tooltip');
  const { zoneTitle, title, glCode, category, measures, contribution, rect } = zone;
  //console.log(zone);

  return (
    <div className={prefix()}>
      {/* {category && <div className={prefix('category')}>{category}</div>} */}
      {(glCode || zoneTitle) && (
        <div className={prefix('header')}>
          {/* {glCode && <div className={prefix('header-gl-code')}>{glCode}</div>} */}
          {zoneTitle && <div className={prefix('header-title')}>{zoneTitle}</div>}
        </div>
      )}
      {title && (
        <div className={prefix('title')}>
          <div className={classNames(prefix('title-label'), { 'no-contribution': !contribution })}>{title}</div>
          {contribution && (
            <div className={prefix('title-contribution')}>
              {'(' + formatAmount(contribution * 100, 2, true, true) + '%)'}
            </div>
          )}
        </div>
      )}
      {measures && (
        <div className={prefix('measures')}>
          {measures.map((measure, index) => (
            <div key={index} className={prefix('measures-measure')}>
              <div
                /* style={{ color: rect.fill }} */
                className={classNames(prefix('measures-measure-title'), { 'notes-enabled': measure.note })}
              >
                {measure.note ? measure.note : measure.name}:
              </div>
              <div className={prefix('measures-measure-value')}>
                {measure.unit === '$'
                  ? formatAmountCondensed(measure.value)
                  : formatAmount(measure.value * 100, 2, true, true) + '%'}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChartTooltip;
