import { parseDataRows } from './dataRows';
import { Column, TransformedData, Accounts } from './types';
import { generateFilteredId } from './utils';
import { analyzeVariance } from './varianceAnalysis';
import { logger } from './utils';

export const createAccountsColumn = (report: NestedHoneReport, transformedData: TransformedData): Column => {
  // Adding the accounts column
  const accounts: Column = {
    name: 'Accounts',
    type: [
      {
        type: 'Account',
      },
    ],
    data: [],
    isLocation: transformedData.multiLocation && !transformedData.consolidated,
    periodReporting: transformedData.periodReporting,
    liveReport: transformedData.liveReport > -1,
    periodID: transformedData.columns[0].periodID,
    date: transformedData.columns[0].date,
    compoundColumn: false, // Accounts column is not a compound column
    originType: 'Accounts',
  };

  // All this below is to get the budget data from dates and mapping it properly into the columns data array
  accounts.data = getDataAccounts(report, transformedData);
  accounts.data?.forEach(account => {
    transformedData.columns.forEach((column: any, columnIndex: number) => {
      const tmpAmount = account.amounts[column.type[0].index];
      column.type.forEach((type: any, typeIndex: number) => {
        if (type.type === 'Budget') {
          // This is to get the budget data from dates and mapping it properly into the columns data array
          tmpAmount.budget = account.amounts[column.type[typeIndex].index].amount;
          tmpAmount.budgetPercent = account.amounts[column.type[typeIndex].index].percent;
          tmpAmount.difference = tmpAmount.amount - tmpAmount.budget;
          tmpAmount.variance = tmpAmount.budget !== 0 ? tmpAmount.difference / tmpAmount.budget : 0;
          tmpAmount.varianceAnalisys = analyzeVariance(account.amountTitle, tmpAmount.variance);
        }
      });
      column.data && column.data.push(tmpAmount);
    });
  });

  return accounts;
};

// Extract the accounts data from the report
export const getDataAccounts = (report: any, transformedData: TransformedData): Accounts[] | undefined => {
  // Let's analyze all levels of the tree and get the lowest one. If it's not 0 then calculate the level difference so we can apply below
  // the correct level to the accounts
  const lowestLevel = report?.sections.reduce((minLevel: number, section: any) => {
    const level = section.level;
    if (section.display && section.display !== 'hidden' && section.display !== 'empty') {
      return level < minLevel ? level : minLevel;
    }
    return minLevel;
  }, Infinity);

  return report?.sections.flatMap((item: any, sectionIndex: number) => {
    const flattenSection = (
      section: any,
      parent: string | null = null,
      isLastChild: boolean = false,
      parentPath: string[] = []
    ): Accounts[] => {
      //GL extraction
      const glCodeMatch = section.title.match(/\b\d{4,5}\b/);
      const glCode = section.glCode || (glCodeMatch ? glCodeMatch[0] : null);
      const title = glCodeMatch ? section.title.replace(/\b\d{4}\b/, '').trim() : section.title;

      //Style extraction
      const displayClass = section.display?.split('_')[0];
      const isBigHeader = displayClass === 'header' && section.level === 0;
      const isTotal = displayClass === 'total';
      const isHeader = section.display === 'total_2' || (displayClass === 'header' && section.level > 0);

      // If display value is a header and all amounts in data are 0 or null then we do not display the values of that row
      const noValuesToShow =
        (isBigHeader || isHeader) &&
        section.data.length > 0 &&
        section.data.every((item: { amount: number | null }) => item.amount === 0 || item.amount === null);

      const filteredId = generateFilteredId(section.id, title);

      const account: Accounts = {
        id: `${filteredId}_@_${sectionIndex}`, // Some indexs are not unique
        cellID: `${filteredId}_@_${sectionIndex}_-x-_0`,
        amountTitle: title,
        refTitle: section.title,
        percentTitle: section.titlePerc,
        baseRef: section.titlePerc !== '' ? section.titlePerc : undefined,
        level: section.level - lowestLevel,
        glCode: glCode,
        parent: parent,
        path: [...parentPath, title],
        display: section.display,
        children: section.sections
          ? section.sections.map((child: any) => `${generateFilteredId(child.id, child.title)}_@_${sectionIndex}`)
          : undefined,
        vizHelpers: {
          lastItem: isLastChild,
          isTotal: isTotal,
          isHeader: isHeader,
          isBigHeader: isBigHeader,
          isExpanded: true,
          isSelected: false,
          noValuesToShow: noValuesToShow,
        },
      };

      const dataR = parseDataRows({
        section,
        transformedData,
        path: [...parentPath, title],
        sectionIndex,
        filteredId,
        title,
        refTitle: section.title,
        vizHelpers: account.vizHelpers,
      });

      account.amounts = dataR.amounts;
      account.anomalies = dataR.anomaly;
      let result: Accounts[] = [account];

      // If we have chiildren, we need to flatten them too
      if (section.sections && section.sections.length > 0) {
        section.sections.forEach((child: any, index: number) => {
          // We detect the last child so we can display the hierarchy correctly
          const isLastChildOfParent = index === section.sections.length - 1;
          result = result.concat(flattenSection(child, filteredId, isLastChildOfParent, [...parentPath, title]));
        });
      }

      return result;
    };

    // Filtering out hidden and empty items
    return item.display !== 'hidden' && item.display !== 'empty'
      ? flattenSection(item).filter(account => account.display !== 'hidden' && account.display !== 'empty')
      : [];
  });
};
