import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { prefixClass } from 'lib/utils';
import NTSearchAndFilter from '../ReportTable/NTSearchAndFilter';
import NTLayout from './NTLayout';
import { NTChart } from './NTChart';
import NTMiniMap from './NTMiniMap';
import NTColumnBrowser from './NTColumnBrowser';
import NTSkeleton from './NTSkeleton';
import { useNTUtilsContext } from './NTUtilsContext';
import { useNTTableContext } from './NTTableContext';
import { useNTSelectionContext } from './NTSelectionContext';
import { NTScrollBar } from './NTScrollBar';
import { useTableState } from './hooks/useTableState';
import { report } from 'process';
import { logger } from '../KSExport/reportNTParser/utils';

type NTMainLayoutProps = {
  parentRef: RefObject<HTMLDivElement>;
  transformedData: any;
  loading: boolean;
  searchTerm: string;
  fixFirstColumn: boolean;
  onSearch: (value: string) => void;
  onClear: () => void;
};

export const NTMainLayout = ({
  parentRef,
  transformedData,
  loading,
  searchTerm,
  fixFirstColumn,
  onSearch,
  onClear,
}: NTMainLayoutProps) => {
  const { actions, capabilities } = useNTUtilsContext();
  const { tableWidth, setTableWidth, setRedimParent } = useNTTableContext();
  const { cellStates, setCellStates, handleSelect, selectRow } = useNTSelectionContext();
  const [rightPaneVisible, setRightPaneVisible] = useState<boolean>(actions.detailsPanel && capabilities.charts);

  const redimParentRef = useRef<HTMLDivElement>(null);
  const rightPaneRef = useRef<HTMLDivElement>(null);
  const prefix = prefixClass('whole-table');

  const reportIncludeFixedColumn = transformedData?.totalColumn;
  const showDifference = transformedData?.difference;

  useTableState();

  useEffect(() => {
    if (actions.detailsPanel && capabilities.charts) {
      //console.log(`enter NTMainLayout second entry point. RIGHT PANE -> width set to 60%`);
      setTableWidth('60%');
    } else {
      //console.log('enter NTMainLayout second entry point.  NO RIGHT PANE -> width to 100%');
      setTableWidth('100%');
    }
    setRightPaneVisible(actions.detailsPanel && capabilities.charts);
  }, [actions.detailsPanel, capabilities.charts]);

  useEffect(() => {
    if (!transformedData || !transformedData.columns.length) return;
    //console.log('enter NTMainLayout first entry point');
    setRedimParent(rightPaneVisible ? redimParentRef.current : null);
  }, [rightPaneVisible, transformedData.columns.length]);

  useEffect(() => {
    setTimeout(() => selectRow(true), 8);
  }, [transformedData.columns.length]);

  const MemoizedComponents = useMemo(
    () => ({
      chart: <NTChart rawData={transformedData} height={320} />,
      miniMap: <NTMiniMap rawData={transformedData} />,
      scrollBar: <NTScrollBar />,
      columnBrowser: <NTColumnBrowser />,
    }),
    [transformedData.columns.length]
  );

  const MemoizedTable = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const comparisonPeriodsFlag = urlParams.get('comparisonPeriods');
    const comparisonPeriods = comparisonPeriodsFlag ? parseInt(comparisonPeriodsFlag) : 1;
    if (loading || !transformedData || !transformedData.columns.length) {
      return <NTSkeleton columns={comparisonPeriods + 1} rows={40} />;
    }
    return (
      <NTLayout
        parentRef={parentRef}
        key="myReportTable"
        data={transformedData}
        fixFC={fixFirstColumn}
        fixLC={actions.total || reportIncludeFixedColumn || actions.difference || showDifference}
        searchTerm={searchTerm}
      />
    );
  }, [loading, transformedData, transformedData.columns.length, searchTerm]);

  return (
    <div
      className={classNames(prefix(), {
        'right-pane-visible': actions.detailsPanel && capabilities.charts,
      })}
    >
      <div
        className={classNames('left-pane', { 'lp-rp-visible': actions.detailsPanel && capabilities.charts })}
        ref={redimParentRef}
        style={{ width: tableWidth }}
      >
        <div className="table-search-wrapper">
          <NTSearchAndFilter
            selectedReportType={transformedData?.type ?? 'P&L Comparison'}
            searchValue={searchTerm}
            onSearch={onSearch}
            onClear={onClear}
          />
          <div className="new-table" id="new-table">
            {MemoizedTable}
          </div>
          {MemoizedComponents.columnBrowser}
        </div>
        {MemoizedComponents[!rightPaneVisible ? 'miniMap' : 'scrollBar']}
      </div>
      <div ref={rightPaneRef} className={classNames('right-pane', { 'rp-visible': actions.detailsPanel })}>
        {rightPaneVisible && MemoizedComponents.chart}
      </div>
    </div>
  );
};
