import { prefixClass } from 'lib/utils';
import { useNTSelectionContext } from '../NTSelectionContext';
import { useNTScroll } from '../NTScrollContext';
import './NTScrollBar.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getCSSVariable } from '../NTMiniMap/NTMiniMapUtils';

const NTScrollBar = () => {
  const prefix = prefixClass('scroll-bar');
  const { cellStates, transformedData } = useNTSelectionContext();
  const { scrollState, setScrollState, setScrollChange } = useNTScroll();
  const [rowHeight, setRowHeight] = useState(0);
  const [scrollAreaHeight, setScrollAreaHeight] = useState(0);
  const [scrollAreaWidth, setScrollAreaWidth] = useState(0);
  const [isWheeling, setIsWheeling] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  // scrollbar visual properties
  const scrollProps = {
    normalColor: getCSSVariable('--ks-app-bg'),
    selectedColor: getCSSVariable('--ks-contrast-highlight'),
    bigHeaderColor: '#e8e8e8',
    headerColor: '#eaeaea',
    totalColor: '#ededed',
    gap: 0,
    margin: 0,
  };

  const scrollRef = useRef<HTMLDivElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);
  const controlRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // Handle the initialization of the scrollbar and set the observer so we can adapt to changes in the size of the scrollbar
  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      if (!transformedData || !transformedData.columns.length) return;
      const rows = transformedData.columns[0].data!;
      if (trackRef.current && rows.length) {
        setRowHeight(trackRef.current.offsetHeight / rows.length);
        setScrollAreaHeight(trackRef.current.offsetHeight);
        setScrollAreaWidth(trackRef.current.offsetWidth);
      }
    });

    if (scrollRef.current) {
      observer.observe(scrollRef.current);
    }

    return () => {
      if (scrollRef.current) {
        observer.disconnect();
      }
    };
  }, [transformedData.columns.length]);

  // Draw the scrollbar
  const drawScrollBar = useCallback(
    (ctx: CanvasRenderingContext2D) => {
      const { normalColor, selectedColor, gap, bigHeaderColor, headerColor, totalColor } = scrollProps;
      const canvas = canvasRef.current;
      if (!canvas || !transformedData || !transformedData.columns.length) return;

      ctx.clearRect(0, 0, scrollAreaWidth, scrollAreaHeight);
      const rows = transformedData.columns[0].data!;
      const selectedRow = cellStates.selected;
      rows.forEach((row: any, index: number) => {
        const y = index * rowHeight;
        ctx.fillStyle =
          row.id === selectedRow
            ? selectedColor
            : row.vizHelpers.isBigHeader
              ? bigHeaderColor
              : row.vizHelpers.isHeader
                ? headerColor
                : row.vizHelpers.isHeader
                  ? totalColor
                  : normalColor;
        ctx.fillRect(0, y, scrollAreaWidth, rowHeight - gap);
      });
    },
    [transformedData.columns.length, cellStates.selected, rowHeight, scrollAreaHeight, scrollAreaWidth]
  );

  // Update the Canvas when the selected row changes or we have new data
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    drawScrollBar(ctx);
  }, [cellStates.selected, rowHeight, scrollAreaHeight, scrollAreaWidth]);

  // When the scroll changes (by scrolling the table)
  useEffect(() => {
    const control = controlRef.current;
    if (!control || isDragging) return;
    const { margin } = scrollProps;
    control.style.top = `${scrollState.verticalScrollPercentage * scrollAreaHeight - margin}px`;
    control.style.height = `${scrollAreaHeight * scrollState.visibleAreaOfScrollVertical + margin * 2}px`;
  }, [scrollState.verticalScrollPercentage, cellStates.selected, rowHeight, isDragging]);

  // Jump to position when the track is clicked
  const jumpTo = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const control = controlRef.current;
    if (!control) return;
    const rect = e.currentTarget.getBoundingClientRect();
    const clickY = e.clientY - rect.top;
    const visibleAreaHeight = control.offsetHeight / 2;
    setTimeout(() => {
      setScrollChange({
        axis: 'y',
        amount: ((clickY - visibleAreaHeight) / scrollAreaHeight) * scrollState.scrollAreaHeight,
      });
    }, 8);
  };

  // Drag Event
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const control = controlRef.current as HTMLDivElement;
    if (!control) return;

    const dragState = {
      startY: e.clientY,
      scrollPosition: e.clientY,
      lastUpdate: 0,
      isDragging: true,
    };

    setIsDragging(true);

    const handleDragMove = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const { margin } = scrollProps;
      const now = Date.now();
      if (now - dragState.lastUpdate < 100) return;

      if (dragState.isDragging) {
        const delta = e.clientY - dragState.scrollPosition;
        let newY = control.offsetTop + delta;
        const maxY = scrollAreaHeight - control.offsetHeight + margin;
        newY = Math.max(margin * -1, Math.min(maxY, newY));
        dragState.scrollPosition = e.clientY;
        control.style.top = `${newY}px`;
        setScrollChange({ axis: 'y', amount: (newY / scrollAreaHeight) * scrollState.scrollAreaHeight });
      }
    };

    const handleDragEnd = () => {
      dragState.isDragging = false;
      setIsDragging(false);
      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('mouseup', handleDragEnd);
    };

    document.addEventListener('mousemove', handleDragMove);
    document.addEventListener('mouseup', handleDragEnd);
  };

  if (!transformedData || !transformedData.columns.length) return null;

  return (
    <div ref={scrollRef} className={prefix()}>
      <div ref={trackRef} className={prefix('track')} onClick={jumpTo}>
        <canvas ref={canvasRef} width={scrollAreaWidth} height={scrollAreaHeight} />
      </div>
      <div ref={controlRef} className={prefix('control')} onMouseDown={handleMouseDown}></div>
    </div>
  );
};
export default NTScrollBar;
