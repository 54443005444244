import { formatRangeValues } from 'lib/reportUtils';
import { TransformedData, Column, Amounts } from './types';
import {
  ChartDataColumn,
  ChartObject,
  ChartProps,
  ChartDataColumnChildren,
} from 'presentation/components/NewTable/NTChart/types';

export const createTotalColumn = (transformedData: TransformedData, difference: boolean = false): Column => {
  const filteredColumns = transformedData.columns.filter((column: Column) => {
    return !column.compoundColumn && column.originType !== 'Total' && column.originType !== 'Difference';
  });
  const totalRowData: Amounts[] = filteredColumns.reduce((acc: any, column: any, index: number) => {
    column.data.forEach((elem: Amounts, rowIndex: number) => {
      if (!acc[rowIndex]) {
        acc[rowIndex] = { ...elem };
        acc[rowIndex].percent = acc[rowIndex].percent || 0;
      } else {
        if (rowIndex > 0 && difference) {
          acc[rowIndex].amount -= elem.amount ? elem.amount : 0;
          acc[rowIndex].percent -= elem.percent ? elem.percent : 0;
        } else {
          acc[rowIndex].baseAmount += elem.baseAmount ? elem.baseAmount : 0;
          acc[rowIndex].percent += elem.percent ? elem.percent : 0;
          acc[rowIndex].dataRows = acc[rowIndex].dataRows.concat(elem.dataRows || []);

          acc[rowIndex].amount += elem.amount ? elem.amount : 0;
          if (index === filteredColumns.length - 1) {
            if (acc[rowIndex].percent !== 0) acc[rowIndex].percent = acc[rowIndex].amount / acc[rowIndex].baseAmount;
            acc[rowIndex].transactions = acc[rowIndex].dataRows.length;
          }
        }
      }
    });
    return acc;
  }, []);

  const totalColumn: Column = {
    name: difference ? 'Difference' : 'Total',
    date: { start: difference ? 'Δ Difference' : '∑ Total', end: '' },
    type: [{ type: 'Direct', index: 0 }],
    data: totalRowData,
    total: true,
    periodReporting: false,
    originType: 'Synthetic ' + (difference ? 'Difference' : 'Total'),
  };
  return totalColumn;
};

const rowById = (transformedData: TransformedData, rowId: string): any => {
  return transformedData.columns[0].data?.findIndex(row => row.id?.startsWith(rowId));
};

export const createChartsValues = (transformedData: TransformedData): ChartObject => {
  const filteredColumns = transformedData.columns.filter((column: Column) => {
    return !column.compoundColumn && column.originType !== 'Total' && column.originType !== 'Difference';
  });
  const rowCharts: ChartObject = {};
  const rowCount = filteredColumns[0]?.data?.length || 0;

  for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
    const title = transformedData.type + ' (' + transformedData.timeFrame + ')';
    const children = filteredColumns[0].data?.[rowIndex].children;
    const isParentAccount = children.length > 0;
    const chart: ChartProps = {
      id: filteredColumns[0].data?.[rowIndex].id,
      info: title,
      name: filteredColumns[0].data?.[rowIndex].amountTitle,
      timeFrame: transformedData.range?.range || '',
      isLocation: transformedData.multiLocation || false,
      total: transformedData.columns[0].data?.[rowIndex].vizHelpers.isTotal || false,
      consolidated: transformedData.consolidated || false,
      display: { money: true, percent: true },
      budget: transformedData.budgetAvailable || false,
      groups: ['Actual', 'Budget', 'Variance'],
      columns: [],
      isParent: isParentAccount,
      glCode: filteredColumns[0].data?.[rowIndex].glCode,
    };

    // let's find is it is a Parent Account and what account it is it's total
    let totalIndex = 0;
    if (isParentAccount) {
      const idparts = chart.id.split('_@_');
      const totalAccount = idparts[0] + '-total_@_';
      totalIndex = rowById(transformedData, totalAccount);
    }

    filteredColumns.forEach((column: Column, index: number) => {
      if (index === 0) return;
      const elem: Amounts = column.data?.[isParentAccount && totalIndex > -1 ? totalIndex : rowIndex] || {};
      const title = formatRangeValues(
        column.date?.start && column.date?.end ? `${column.date?.start} - ${column.date?.end}` : column.date?.start,
        'KS'
      );

      let name = title || column.name || '';
      if (column.isLocation) {
        name = column.name || 'Location';
      }
      const col: ChartDataColumn = {
        name: name,
        liveReport: column.liveReport || false,
        data: {
          money: { actual: elem.amount || 0, budget: elem.budget || 0, variance: elem.difference || 0 },
          percent: { actual: elem.percent || 0, budget: elem.budgetPercent || 0, variance: elem.variance || 0 },
        },
      };

      if (isParentAccount) {
        const columnChildren = children
          .map((child: any) => {
            const childIndex = rowById(transformedData, child);
            if (childIndex < 0) return null;
            const childElem: Amounts = column.data?.[childIndex] || {};
            if (childElem.id.indexOf('-total_@_') > -1) return null;
            const childAccount = filteredColumns[0].data?.[childIndex];
            const hasChildren = childAccount.children?.length > 0;
            let childrenTotalIndex = 0;
            if (hasChildren) {
              const childAccountId = childAccount.id;
              const childAccountIdParts = childAccountId.split('_@_');
              const childAccountTotal = childAccountIdParts[0] + '-total_@_';
              childrenTotalIndex = rowById(transformedData, childAccountTotal);
            }
            const childCol: ChartDataColumnChildren = {
              id: childElem.id,
              glCode: childAccount.glCode,
              title: childElem.title,
              money:
                hasChildren && childrenTotalIndex > -1
                  ? column.data?.[childrenTotalIndex].amount
                  : childElem.amount || 0,
              percent:
                hasChildren && childrenTotalIndex > -1
                  ? column.data?.[childrenTotalIndex].percent
                  : childElem.percent || 0,
            };
            return childCol;
          })
          .filter(Boolean);
        col.childrenAccounts = columnChildren;
      }
      chart.columns.push(col);
    });
    rowCharts[chart.id] = chart;
  }
  return rowCharts;
};
