import { useRef, useState, useMemo, useEffect, useCallback, memo } from 'react';
import { useNTContext, filters } from './NTContext';
import { useNTUtilsContext, actions } from './NTUtilsContext';
import { useNTTableContext } from './NTTableContext';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { useReportsStore } from 'hooks/useReportsStore';
import { shallow } from 'zustand/shallow';
import { useFlaggedTransactionsQuery } from 'hooks/flaggedTransactions';
import useAttachmentsModal from 'hooks/useAttachmentsModal';
import { NewTableProps } from './types';
import { useModalState } from './hooks/useModalState';
import { useColumnWidths } from './hooks/useColumnWidths';
import parseReportNT from '../KSExport/reportNTParser';
import { readURLParams, saveURLParams } from './utils/urlParams';
import { NTMainLayout } from './NTMainLayout';
import { useNTScroll } from './NTScrollContext';
import { useNTSelectionContext } from './NTSelectionContext';
import { getReportCapabilities } from '../KSExport/reportNTParser/capabilities';
import DataRowModal from 'components/Table/DataRowModal';
import { logger } from '../KSExport/reportNTParser/utils';

const MemoizedNewTableContent = memo(
  ({
    report,
    fixFirstColumn = false,
    fixLastColumn = false,
    loading = false,
    redraw = false,
    reportType,
  }: NewTableProps) => {
    // Context hooks first
    const { filters, setFilters, budgetAvailable = false, setBudgetAvailable } = useNTContext();
    const { actions, setActions, capabilities, setCapabilities } = useNTUtilsContext();
    const { transformedData, setTransformedData, cellStates, setCellStates, handleSelect } = useNTSelectionContext();
    const { tableWidth, setTableWidth, setRedimParent } = useNTTableContext();

    // Calculate column widths with stable dependencies
    useColumnWidths(transformedData, filters, capabilities, budgetAvailable || false);

    // Refs
    const parentRef = useRef<HTMLDivElement>(null);
    const trace = false;

    // State hooks
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { modalState, handleClose, showModal } = useModalState();

    // Data fetching hooks
    const currentLocation = useLocationsStore(state => state.currentLocation);
    const currentLocationId = useLocationsStore(state => state.currentLocationId);
    const reportsConfiguration = useReportsStore(state => state.reportsConfiguration, shallow);
    const { data: flaggedTransactions } = useFlaggedTransactionsQuery();
    const { getAttachmentsUrl } = useAttachmentsModal();

    // Memoized callbacks
    const handleSearch = useCallback((value: string) => {
      setSearchTerm(value);
    }, []);
    const handleClear = useCallback(() => setSearchTerm(''), []);

    // States
    const [isInitialized, setIsInitialized] = useState(false);
    const [envReady, setEnvReady] = useState(false);
    const [ready, setReady] = useState(false);

    const mustCleanData = useRef(true);

    useEffect(() => {
      setReady(false);
      setEnvReady(false);
      setTransformedData(null);
    }, []);

    // First Step - Fetching data
    useEffect(() => {
      if (loading) {
        if (ready) setReady(false);
        setEnvReady(false);
        setTransformedData(null);
      }
    }, [loading]);

    // Second Step - Parsing data
    useEffect(() => {
      if (!report) return;
      if (ready) setReady(false);
      if (envReady) setEnvReady(false);
      // console.log('Report received ...');
    }, [report?.createdAt, report?.type, report?.dates.length, report?.dates, report?.title]);

    // Instead of directly calling setTransformedData with useMemo
    const transformedDataValue = useMemo(
      () =>
        report && envReady && !ready
          ? parseReportNT({
              report,
              useComputedColumns: true,
              periodReporting: reportsConfiguration?.data?.periodReporting,
              reportCFG: reportsConfiguration,
              flaggedTransactions,
              currentLocation,
              budget: reportType === 'budget',
              trace,
            })
          : null,
      [
        report,
        report?.type,
        report?.createdAt,
        reportsConfiguration?.data.periodReporting,
        flaggedTransactions,
        currentLocation,
        trace,
        envReady,
        ready,
      ]
    );

    // Use useEffect to set the transformed data
    // Set Ready to true when the transformed data is set
    useEffect(() => {
      if (!transformedDataValue || !envReady) return;
      //console.log('Setting transformed data', transformedDataValue, envReady);
      setTransformedData(transformedDataValue);
      if (!cellStates.selected && transformedDataValue.columns && transformedDataValue.columns[0]?.data) {
        setCellStates({ ...cellStates, selected: transformedDataValue.columns[0].data[1]?.id });
      }
      setReady(true);
    }, [transformedDataValue, setTransformedData, envReady]);

    // Read URL params as well as capabilities to set the environment
    useEffect(() => {
      const { filter, actions: urlActions, selection, urlBudgetInclude } = readURLParams();
      if (!report || !report.type) return;
      const initCapabilities = getReportCapabilities(reportType === 'budget' ? 'Budget' : report!.type);
      setCapabilities(initCapabilities);
      setBudgetAvailable(urlBudgetInclude);
      setFilters({ ...filters, ...filter });
      setActions({
        ...actions,
        total: urlActions.total,
        difference: urlActions.difference,
        reverseColumns: urlActions.reverseColumns,
        detailsPanel: urlActions.detailsPanel,
      });
      setCellStates({ ...cellStates, selected: selection.rowID });
      const mustOpenDetailsPanel = urlActions.detailsPanel && initCapabilities.charts;
      setTableWidth(mustOpenDetailsPanel ? '60%' : '100%');
      //console.log('Setting URL', urlActions, filters, actions, urlBudgetInclude);
      setEnvReady(true);
    }, [report, report?.type]);

    // Sync URL params when filters or actions change
    useEffect(() => {
      if (!envReady) return;
      saveURLParams({ filters, actions, cellState: cellStates });
    }, [envReady, filters, actions, cellStates]);

    // Memoize the NTMainLayout component. It will be drawn when is ready
    const MemoizedNTMainLayout = useMemo(() => {
      if (!envReady) return null;
      return (
        <NTMainLayout
          parentRef={parentRef}
          transformedData={transformedData}
          loading={!ready || loading}
          searchTerm={searchTerm}
          fixFirstColumn={fixFirstColumn}
          onSearch={handleSearch}
          onClear={handleClear}
        />
      );
    }, [ready, envReady, report?.type, searchTerm]);

    // Early return with stable condition
    if (!report || !transformedData || !transformedData.columns.length) {
      if (!report) {
        if (!mustCleanData.current) return;
        mustCleanData.current = false;
        return <div>Requesting Report ...</div>;
      }
    }

    return (
      <>
        {MemoizedNTMainLayout}
        {showModal && (
          <DataRowModal
            modalState={modalState}
            handleClose={handleClose}
            showAttachment={(attachments, amount) => getAttachmentsUrl(currentLocationId, attachments, amount)}
          />
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    // Only re-render if these props change
    return (
      prevProps.report === nextProps.report &&
      prevProps.report?.createdAt === nextProps.report?.createdAt &&
      prevProps.loading === nextProps.loading &&
      prevProps.fixFirstColumn === nextProps.fixFirstColumn &&
      prevProps.fixLastColumn === nextProps.fixLastColumn &&
      prevProps.redraw === nextProps.redraw
    );
  }
);

export const NewTableContent: React.FC<NewTableProps> = props => {
  // Get scroll context here but don't pass it down
  const { scrollState, scrollChange } = useNTScroll();
  // TODO: Initialize the ScrollState here
  return <MemoizedNewTableContent {...props} />;
};
