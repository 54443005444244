import { Whisper, Tooltip } from 'rsuite';
import { Amounts } from '../../../KSExport/reportNTParser/types';
import { useNTUtilsContext } from '../../NTUtilsContext';
import { DataCellState } from 'domain/models';
import { makeFindMonthlyDatarows, makeGetReportAttachments } from 'main/factories/usecases';
import { Location as KsLocation } from '@hone-automation/common';
import { initialDataCellState } from 'hooks/useActiveReportStore';

export type WhisperTooltip = {
  title: string;
  value: string | number;
  style?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
};

export const formatWhisperTooltip = (tooltipData: WhisperTooltip[]) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
      {tooltipData.map((item, index) => {
        return (
          <div
            key={index}
            style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', ...(item.style || {}) }}
          >
            {item.value !== '' ? (
              <>
                <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item.title}</div>
                <b style={{ flexShrink: 0, ...(item.valueStyle || {}) }}>{item.value}</b>
              </>
            ) : (
              <div>{item.title}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const getReportAttachmentsAsync = async (reportTransactionsRows: any[], currentLocationId: string) => {
  const reportAttachmentsService = makeGetReportAttachments();
  return reportAttachmentsService.getReportAttachments({
    locationId: currentLocationId!,
    dataRows: reportTransactionsRows,
  }) as Promise<any[]>;
};

export const trxModalPipe = async (cellData: Amounts, currentLocation?: KsLocation): Promise<DataCellState> => {
  //Transpile amounts to DataCellState;
  const dataRowsAttachments = currentLocation
    ? await getReportAttachmentsAsync(cellData.dataRows, currentLocation.id)
    : null;
  const modalData: DataCellState = {
    modalOpen: true,
    audit: null,
    dataRows: dataRowsAttachments || cellData.dataRows,
    date: {
      start: cellData.date,
      end: '',
    },
    title: cellData.title,
    parent: cellData.path?.[0] || null,
    total: cellData.amount || 0,
    loading: false,
    hasDataRowsOrAudit: cellData.dataRows.length > 0,
  };
  return modalData;
};
