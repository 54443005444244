import { GetBudgets } from '../../../domain/usecases';

import { BaseHttpService } from 'main/factories/http';

import { GetBudgetRequest } from '@hone-automation/common';

export class RemoteGetBudgets extends BaseHttpService<GetBudgets.Model> implements GetBudgets {
  async getBudgets(params: GetBudgetRequest): Promise<GetBudgets.Model> {
    return await this.request(
      'get',
      {},
      {
        ...params,
      }
    );
  }
}
