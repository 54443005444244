import { Capabilities } from './types';

type extendedHoneReportType = HoneReportType | 'Budget';

export const getReportCapabilities = (reportType: extendedHoneReportType): Capabilities => {
  switch (reportType) {
    case 'P&L Comparison':
    case 'Income Statement':
      return {
        money: true,
        budget: true,
        percentage: true,
        transactions: true,
        charts: true,
        sparklines: 'bar',
        smoothing: true,
        anomalies: false,
        totalRow: false,
        variance: false,
        synthetic: false,
        flaggedTransactions: true,
        liveReport: true,
      };
    case 'Balance Sheet':
      return {
        money: true,
        budget: false,
        percentage: false,
        transactions: true,
        charts: false,
        sparklines: 'line',
        smoothing: false,
        anomalies: false,
        totalRow: false,
        variance: false,
        synthetic: false,
        flaggedTransactions: true,
        liveReport: true,
      };
    case 'Cash Flow':
      return {
        money: true,
        budget: false,
        percentage: false,
        transactions: false,
        charts: false,
        sparklines: 'none',
        smoothing: false,
        anomalies: false,
        totalRow: false,
        variance: false,
        synthetic: false,
        flaggedTransactions: true,
        liveReport: true,
      };
    case 'AP Aging':
      return {
        money: true,
        budget: false,
        percentage: false,
        transactions: true,
        charts: false,
        sparklines: 'none',
        smoothing: false,
        anomalies: false,
        totalRow: false,
        variance: false,
        synthetic: false,
        flaggedTransactions: true,
        liveReport: true,
      };
    case 'Budget':
      return {
        money: true,
        budget: false,
        percentage: true,
        transactions: false,
        charts: false,
        sparklines: 'bar',
        smoothing: false,
        anomalies: false,
        totalRow: false,
        variance: false,
        synthetic: true,
        flaggedTransactions: false,
        liveReport: false,
      };
  }
};
