import './ChartBlock.scss';
import { prefixClass } from 'lib/utils';
import { ChartProps, ChartFilters, ChartDimensions, TooltipZone, TooltipDataProps } from '../types';
import { NTToolTip } from '../../NTToolTip';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { drawChart } from './chartBuilder';
import { useNTSelectionContext } from '../../NTSelectionContext';
import React from 'react';
import ChartTooltip from './ChartTooltip';
import classNames from 'classnames';

type ChartBlockProps = {
  dimensions: ChartDimensions;
  chartData: ChartProps | null;
  chartFilters: ChartFilters;
};

type insideResult = { zone: TooltipZone | null; foundArea: number };

const mouseInsideArea = (tooltips: TooltipZone[] | null, mouseX: number, mouseY: number): insideResult => {
  const output: insideResult = { zone: null, foundArea: -1 };
  tooltips?.forEach(zone => {
    let foundArea = -1;
    zone.areas.forEach((area, areaIndex) => {
      const { x, y, width, height } = area.rect;
      if (mouseX >= x && mouseX <= x + width && mouseY >= y && mouseY <= y + height) {
        //console.log('Mouse is inside the area ', zone.title, ' ', area.title);
        foundArea = areaIndex;
        return;
      }
    });
    if (foundArea > -1) {
      output.zone = zone;
      output.foundArea = foundArea;
      return;
    }
  });
  return output;
};

const ChartBlock = ({ dimensions, chartData, chartFilters }: ChartBlockProps) => {
  // Exit conditions before Hooks are called
  //if (!chartData || !chartData.columns.length) return null;

  // Local state
  const [tooltipData, setTooltipData] = useState<TooltipDataProps | null>(null);
  const [tooltipInfo, setTooltipInfo] = useState<React.ReactNode | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0, w: 0, h: 0 });
  const [tooltips, setTooltips] = useState<TooltipZone[] | null>([]);
  const { handleSelect } = useNTSelectionContext();
  const [selectedPeriod, setSelectedPeriod] = useState<number>(0);
  const [hoveredPeriod, setHoveredPeriod] = useState<number>(-1);

  //
  const [margins, setMargins] = useState({ horizontal: 0, vertical: 0 });

  const MemoizedTooltip = useMemo(
    () => <NTToolTip position={tooltipPosition}>{tooltipInfo}</NTToolTip>,
    [tooltipInfo, tooltipPosition]
  );

  useEffect(() => {
    const computedStyle = getComputedStyle(document.documentElement);
    const horizontalMargin = parseInt(computedStyle.getPropertyValue('--ks-chart-margin-horizontal')) || 0;
    const verticalMargin = parseInt(computedStyle.getPropertyValue('--ks-chart-margin-vertical')) || 0;
    setMargins({ horizontal: horizontalMargin, vertical: verticalMargin });
  }, []);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const prefix = prefixClass('chart-area');

  const debouncedDrawChart = useCallback(
    debounce(
      (params: {
        canvasRef: React.RefObject<HTMLCanvasElement>;
        dimensions: ChartDimensions;
        chartData: ChartProps | null;
        chartFilters: ChartFilters;
        selectedPeriod: number;
        hoveredPeriod: number;
      }) => {
        //console.log('zone', chartData);
        const tooltips = drawChart(params);
        setTooltips(tooltips ? tooltips.filter((tooltip): tooltip is TooltipZone => tooltip !== null) : null);
      },
      16,
      { leading: true, trailing: true }
    ),
    []
  );

  useEffect(() => {
    debouncedDrawChart({
      canvasRef,
      dimensions,
      chartData,
      chartFilters,
      selectedPeriod,
      hoveredPeriod,
    });
  }, [chartData, dimensions, selectedPeriod, hoveredPeriod, chartFilters, debouncedDrawChart]);

  /* useEffect(() => {
    
  }, [hoveredPeriod]); */

  useEffect(() => {
    if (!tooltipData) {
      setTooltipInfo(null);
      return;
    }
    setTooltipPosition({
      x: tooltipData.rect.x + margins.horizontal + tooltipData.rect.width / 2,
      y: tooltipData.rect.y + margins.vertical,
      w: tooltipData.rect.width,
      h: tooltipData.rect.height,
    });
    setTooltipInfo(<ChartTooltip zone={tooltipData} />);
  }, [tooltipData]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLCanvasElement>) => {
      setTooltipData(null);
      const canvas = canvasRef?.current;
      if (!canvas || !chartData) return;

      const rect = canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      const { zone, foundArea } = mouseInsideArea(tooltips, mouseX, mouseY);
      if (!zone) return;

      //console.log('zone', zone, chartData);
      if (zone.areas[foundArea].link) {
        const link = zone.areas[foundArea].link;
        if (link) handleSelect(link);
      } else if (zone.areas[foundArea].period) {
        const period = zone.areas[foundArea].period;
        if (period) setSelectedPeriod(period);
      }
    },
    [tooltips]
  );

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<HTMLCanvasElement>) => {
      setTooltipData(null);
      const canvas = canvasRef?.current;
      if (!canvas || !chartData) return;

      const rect = canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      const { zone, foundArea } = mouseInsideArea(tooltips, mouseX, mouseY);
      if (!zone) {
        setTooltipData(null);
        setHoveredPeriod(-1);
      } else {
        if (zone.areas[foundArea].period !== hoveredPeriod) {
          const period = zone.areas[foundArea].period || -1;
          setHoveredPeriod(period);
        }
        setTooltipData({ ...zone.areas[foundArea], zoneTitle: zone.title });
      }
    },
    [tooltips]
  );

  return (
    <div
      className={classNames(prefix(), {
        pointer: tooltipData && (tooltipData.link || tooltipData.periodLink),
      })}
    >
      <canvas
        ref={canvasRef}
        width={dimensions.width}
        height={dimensions.height}
        onClick={handleClick}
        onMouseMove={handleMouseMove}
        onMouseLeave={e => {
          setTooltipData(null);
          setHoveredPeriod(-1);
        }}
      />
      {MemoizedTooltip}
    </div>
  );
};
export default ChartBlock;
