import './NTHeader.scss';
import { getCustomPeriodLabel, prefixClass } from 'lib/utils';
import { useNTContext } from '../NTContext';
import { useNTUtilsContext } from '../NTUtilsContext';
import { Column } from '../../KSExport/reportNTParser/types';
import { IconChevron, IconChevronDown } from 'components/Icons';
import { formatRangeValues } from 'lib/reportUtils';
import classNames from 'classnames';
import { handleCollapseAll } from '../utils/tableUtils';
import { Whisper, Tooltip } from 'rsuite';
import { useNTTableContext } from '../NTTableContext';
import { useEffect, useState } from 'react';

type NTHeaderProps = {
  headerData: any | null;
  parent?: React.RefObject<HTMLDivElement>;
  style?: React.CSSProperties;
  columnID: number;
};

const prefix = prefixClass('nt-header');

const NTKSHeaderAccount = ({ columnID, headerData }: { columnID: number; headerData: Column }) => {
  const { filters } = useNTContext();
  const { start, end } = headerData.date || {};
  return (
    <div className={prefix('ks-account-header')}>
      <Whisper placement="top" trigger="hover" speaker={<Tooltip>Expand/Collapse All</Tooltip>} delayOpen={400}>
        <div
          className={prefix('ks-account-header-collapse')}
          onClick={e => handleCollapseAll(e, 'collapse-all', headerData.data || [])}
          data-collapse-control="collapse-all"
        >
          <div className={`${prefix('ks-account-header-collapse-icons')}`}>
            <div className={`${prefix('ks-account-header-collapse-icons-icon')}`}>
              <IconChevron />
            </div>
            <div className={`${prefix('ks-account-header-collapse-icons-icon')}`}>
              <IconChevronDown />
            </div>
          </div>
        </div>
      </Whisper>
      <div className={prefix('ks-account-header-label')}>Accounts</div>
      {headerData.liveReport && <div className={prefix('ks-account-header-live')}>Live Report</div>}
      {headerData.isLocation && (
        <div className={prefix('ks-account-header-label-date')}>
          {headerData.periodReporting && <span>{headerData.periodID}</span>}
          {formatRangeValues(start && end ? `${start} - ${end}` : start, 'KS')}
        </div>
      )}
    </div>
  );
};
const NTKSHeaderData = ({ columnID, headerData }: { columnID: number; headerData: Column }) => {
  const { filters, budgetAvailable } = useNTContext();
  const { reportType } = useNTTableContext();

  const { capabilities, vFilters } = useNTUtilsContext();
  const { start, end } = headerData.date || {};
  const [oneUnit, setOneUnit] = useState(false);
  const title =
    headerData.isLocation && !headerData.consolidated
      ? headerData.name
      : headerData.type[0].type === 'Direct'
        ? start
        : formatRangeValues(start && end ? `${start} - ${end}` : start, 'KS');

  const [fTitle, setFTitle] = useState<JSX.Element>(<p>{title}</p>);

  const showDetailsRow = capabilities.budget && budgetAvailable && filters.budget && filters.actuals;

  // if we only have one element to display : money, percentages or trasactions we do not display that line
  useEffect(() => {
    const iflags = [];
    if (capabilities.money && filters.money) iflags.push('money');
    if (capabilities.percentage && filters.percentage) iflags.push('percentage');
    if (capabilities.transactions && filters.transactions) iflags.push('transactions');

    // Check if we have two different months like Jan 22 - Feb 18, 2024 (contains " - " followed by a month name)
    const hasTwoMonths = title?.match(/ - [A-Za-z]+/);
    // Check if we have two different months in two different years like Dec 25, 2023 - Jan 21, 2024 (contains 2 commas ", " and a ' - ')
    const hasTwoYears = (title?.match(/,/g) || []).length > 1;

    setOneUnit(iflags.length === 1);
    if (
      title &&
      (!headerData.isLocation || (headerData.isLocation && !headerData.consolidated)) &&
      // We only have one unit or we have 2 but one of them is Transactions
      (iflags.length === 1 || (iflags.length === 2 && iflags.includes('transactions'))) &&
      // We do not have budget or we have budget but we don't have actuals and budget active at the same time or we have two years in the same title
      (!budgetAvailable || !(capabilities.budget && filters.budget && filters.actuals) || hasTwoYears)
    ) {
      if (headerData.isLocation && !headerData.consolidated) {
        // Dealing with a Multilocation Headers
        const titleParts = title.split('-');
        const name = titleParts[0].trim();
        const group = titleParts.length > 1 && titleParts[1].trim();
        //console.log('name:', name, group);
        const rfTitle = (
          <>
            <p>{name}</p>
            <p>
              <span>{group}</span>
            </p>
          </>
        );
        setFTitle(rfTitle);
      } else {
        // We need to handle two date range formats:
        // 1. Same month: "Oct 21 - 27, 2024"
        // 2. Different months: "Oct 28 - Nov 3, 2024"
        const titleParts = title.split(hasTwoYears ? '-' : ',');
        const dateRange = titleParts[0].trim();
        const year = titleParts.length > 1 && titleParts[1].trim();

        const rfTitle =
          hasTwoMonths && !hasTwoYears ? (
            <>
              <p>
                {dateRange.split(' - ')[0]} <span>-</span> {dateRange.split(' - ')[1]}
              </p>
              <p>
                <span>{year}</span>
              </p>
            </>
          ) : (
            <>
              <p>{dateRange}</p>
              <p>
                <span className={hasTwoYears ? 'two-years' : ''}>{year}</span>
              </p>
            </>
          );
        setFTitle(rfTitle);
      }
    } else {
      if (title && headerData.isLocation && !headerData.consolidated) {
        // Dealing with a Multilocation Headers
        const titleParts = title.split('-');
        const name = titleParts[0].trim();
        const group = titleParts.length > 1 && titleParts[1].trim();
        const rfTitle = (
          <>
            <p>{name}</p>
            <p>
              <span>{group}</span>
            </p>
          </>
        );
        setFTitle(rfTitle);
      } else setFTitle(<p>{title}</p>);
    }
  }, [filters]);

  return (
    <div key={`${headerData}-${columnID}`} className={prefix('ks-data-header')}>
      <div
        className={classNames(prefix('ks-data-header-label-container'), {
          percentage: filters.percentage,
        })}
      >
        <div
          className={classNames(prefix('ks-data-header-label-container-label'), {
            location: headerData.isLocation && !headerData.consolidated,
            period: headerData.periodReporting && !oneUnit,
          })}
        >
          {headerData.periodReporting && !(headerData.isLocation && !headerData.consolidated) && (
            <p>
              <span>{headerData.periodID}</span>
            </p>
          )}
          {fTitle}
        </div>
      </div>
      {(!oneUnit || showDetailsRow) && (
        <div className={prefix('ks-data-header-details')}>
          {showDetailsRow && (
            <div
              className={classNames(prefix('ks-data-header-details-titles-container'), {
                trx: filters.transactions,
                ['one-unit']: oneUnit,
              })}
            >
              <div
                className={classNames(prefix('ks-data-header-details-titles-container-data'), {
                  ['percentage-only']: filters.percentage && !filters.money,
                  ['money-only']: !filters.percentage && filters.money,
                })}
              >
                actuals
              </div>
              <div
                className={classNames(prefix('ks-data-header-details-titles-container-data'), {
                  ['percentage-only']: filters.percentage && !filters.money,
                  ['money-only']: !filters.percentage && filters.money,
                  budget: true,
                })}
              >
                budget
              </div>
              {filters.variance && (
                <div
                  className={classNames(prefix('ks-data-header-details-titles-container-data'), {
                    ['percentage-only']: filters.percentage && !filters.money,
                    ['money-only']: !filters.percentage && filters.money,
                    variance: true,
                    ['variance-both']: filters.percentage && filters.money,
                  })}
                >
                  variance
                </div>
              )}
            </div>
          )}
          {!oneUnit && (
            <div className={prefix('ks-data-header-details-units')}>
              {capabilities.transactions && filters.transactions && (
                <div className={prefix('ks-data-header-details-units-transactions')}>●</div>
              )}
              {filters.actuals && (
                <div
                  className={classNames(prefix('ks-data-header-details-units-data'), {
                    percentage: filters.percentage && filters.money,
                  })}
                >
                  {capabilities.money && filters.money && (
                    <div className={prefix('ks-data-header-details-units-data-money')}>$</div>
                  )}
                  {capabilities.percentage && filters.percentage && (
                    <div className={prefix('ks-data-header-details-units-data-percentage')}>%</div>
                  )}
                </div>
              )}
              {capabilities.budget && budgetAvailable && filters.budget && (
                <div
                  className={classNames(prefix('ks-data-header-details-units-data'), {
                    percentage: filters.percentage && filters.money,
                    budget: true,
                    'no-separator': !filters.actuals,
                  })}
                >
                  {capabilities.money && filters.money && (
                    <div className={classNames(prefix('ks-data-header-details-units-data-money'))}>$</div>
                  )}
                  {capabilities.percentage && filters.percentage && (
                    <div className={prefix('ks-data-header-details-units-data-percentage')}>%</div>
                  )}
                </div>
              )}
              {capabilities.budget && budgetAvailable && filters.budget && filters.actuals && filters.variance && (
                <div
                  className={classNames(prefix('ks-data-header-details-units-variance'), {
                    percentage: filters.percentage && filters.money,
                    budget: true,
                    'no-separator': !filters.actuals,
                  })}
                >
                  {capabilities.money && filters.money && (
                    <div className={classNames(prefix('ks-data-header-details-units-data-money'))}>$</div>
                  )}
                  {capabilities.percentage && filters.percentage && (
                    <div className={prefix('ks-data-header-details-units-data-percentage')}>Δ</div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
const NTKSHeader = ({ headerData, parent, columnID, style }: NTHeaderProps) => {
  const { filters } = useNTContext();
  /* console.log('headerData:', headerData); */
  switch (headerData.type[0].type) {
    case 'Account':
      return <NTKSHeaderAccount columnID={columnID} headerData={headerData} />;
    case 'Data':
    case 'Direct':
      return <NTKSHeaderData columnID={columnID} headerData={headerData} />;
  }
};

const NTHeader = ({ headerData, parent, columnID, style }: NTHeaderProps) => {
  const prefix = prefixClass('nt-header');

  switch (typeof headerData) {
    case 'object':
      return <NTKSHeader headerData={headerData} columnID={columnID} />;
    case 'string':
      return (
        <div style={style} key={`${headerData}-${columnID}`} className={`${prefix()} simply-header`}>
          {headerData}
        </div>
      );
  }
};

export default NTHeader;
