import classNames from 'classnames';
import { Whisper, Tooltip } from 'rsuite';
import { useNTContext } from '../../NTContext';
import { useNTUtilsContext } from '../../NTUtilsContext';
import { Amounts } from '../../../KSExport/reportNTParser/types';
import { prefixClass } from 'lib/utils';
import { FormatActual, FormatPercentage, formatValue, formatAmount } from '../ValueFormatters/ValueFormatters';
import { IconFlagFilled, IconFlag, IconRequest } from 'components/Icons';

import { formatWhisperTooltip, WhisperTooltip, trxModalPipe } from './formatReportValuesUtils';
import './FormatReportValues.scss';
import { useLocationsStore } from 'hooks/useLocationsStore';

//type WhisperTooltip = { title: string; value: string | number; style?: React.CSSProperties };

//type WhisperTooltip = { title: string; value: string | number; style?: React.CSSProperties };

const FormatReportValues = ({
  cellData,
  cellID,
  rowID,
  totalColumn = false,
}: {
  cellData: Amounts;
  cellID: string | null;
  rowID?: string | null;
  totalColumn?: boolean;
}) => {
  const prefix = prefixClass('report-values');
  const { filters, budgetAvailable, columns } = useNTContext();
  const { actions, setActions, capabilities } = useNTUtilsContext();
  const {
    index,
    date,
    transactions,
    amount,
    amountAVG,
    percent,
    percentAVG,
    budget,
    budgetPercent,
    difference,
    variance,
    varianceAnalisys,
    vizHelpers,
    anomaly,
    trxAnomaly,
    dataRows,
    trxFlagged,
  } = cellData;
  const { smoothing, anomalies } = filters;
  const currentLocation = useLocationsStore(state => state.currentLocation);

  // We decide to apply smoothing if the smoothing filter is active and if amountAVG is not undefined and it does not have the same value as amount
  const mustSmooth = smoothing && !vizHelpers.noValuesToShow && amountAVG !== undefined && amountAVG !== amount;
  const composedAmount = mustSmooth ? amountAVG : amount;
  const composedPercent = mustSmooth ? percentAVG : percent;

  // Right now anomaly is only shown if the smoothing filter is not active and if anomalies is not undefined and it does not have the same value as amount
  const anomalyValue = anomaly && anomaly.anomalyScores[index];
  const showAnomaly =
    capabilities.anomalies &&
    !mustSmooth &&
    !totalColumn &&
    anomalies &&
    columns > 2 &&
    anomalyValue !== 0 &&
    anomalyValue !== undefined;

  // Anomalies calculation
  const trxAnomalyValue = trxAnomaly && trxAnomaly.anomalyScores[index];
  const showtrxAnomaly =
    capabilities.anomalies &&
    anomalies &&
    !totalColumn &&
    trxAnomaly &&
    columns > 3 &&
    trxAnomalyValue !== 0 &&
    trxAnomalyValue !== undefined;

  const formatComposedAmount = (budget: number | null = null, small: boolean = false) => {
    //Smoothing info
    if (mustSmooth) {
      const tooltip = formatWhisperTooltip([
        { title: 'Value', value: formatAmount(amount) },
        { title: 'Smoothing', value: formatAmount(amountAVG), valueStyle: { color: 'var(--ks-smoothing-color-text)' } },
      ]);
      return (
        <Whisper placement="right" trigger="hover" delayOpen={400} speaker={<Tooltip>{tooltip}</Tooltip>}>
          {FormatActual(budget ? budget : composedAmount, vizHelpers.isTotal, small)}
        </Whisper>
      );
    }

    return FormatActual(budget ? budget : composedAmount, vizHelpers.isTotal, small);
  };

  const formatVariance = (children: React.ReactElement) => {
    // stats info
    if (varianceAnalisys && varianceAnalisys.tendency !== 'neutral') {
      const tooltip =
        amount !== null &&
        formatWhisperTooltip([
          {
            title: varianceAnalisys.category,
            value: varianceAnalisys.tendency,
            style: { padding: '10px 0', fontWeight: 'bold' },
            valueStyle: {
              color:
                varianceAnalisys.tendency === 'positive'
                  ? 'var(--ks-anomaly-positive-contrast-color)'
                  : 'var(--ks-anomaly-negative-contrast-color)',
            },
          },
          { title: varianceAnalisys.message, value: '', style: { opacity: 0.7 } },
          {
            title: varianceAnalisys.explanation || '',
            value: '',
            style: { fontSize: '12px', padding: '10px 0', color: 'var(--ks-white)' },
          },
        ]);
      return (
        <Whisper placement="top" trigger="hover" delayOpen={400} speaker={<Tooltip>{tooltip}</Tooltip>}>
          {children}
        </Whisper>
      );
    }
    return children;
  };

  const formatActualsBlock = (children: React.ReactElement) => {
    // stats info
    if (showAnomaly) {
      const deviation = amount !== null ? amount - anomaly.stats?.mean : 0;
      const tooltip =
        amount !== null &&
        formatWhisperTooltip([
          { title: 'Average', value: formatAmount(anomaly.stats?.mean) },
          {
            title: 'Deviation',
            value: formatAmount(deviation),
            valueStyle: {
              color:
                deviation > 0
                  ? 'var(--ks-anomaly-positive-contrast-color)'
                  : 'var(--ks-anomaly-negative-contrast-color)',
            },
          },
        ]);
      return (
        <Whisper placement="top" trigger="hover" delayOpen={800} speaker={<Tooltip>{tooltip}</Tooltip>}>
          {children}
        </Whisper>
      );
    }
    return children;
  };

  const formatTransactions = (children: React.ReactElement) => {
    // stats info
    if (showtrxAnomaly) {
      const tooltip =
        transactions !== null &&
        formatWhisperTooltip([
          { title: 'Transactions', value: transactions },
          { title: 'Mean', value: formatValue(trxAnomaly.stats?.mean) },
          { title: 'Deviation', value: formatValue(transactions - trxAnomaly.stats?.mean) },
        ]);
      return (
        <Whisper placement="top" trigger="hover" delayOpen={400} speaker={<Tooltip>{tooltip}</Tooltip>}>
          {children}
        </Whisper>
      );
    }

    if (transactions && transactions > 0) {
      const tooltipData: WhisperTooltip[] = [];
      // Only display the amount of transactions if there are more than one transaction
      if (transactions > 1) {
        tooltipData.push({
          title: `${transactions} Transaction${transactions && transactions > 1 ? 's' : ''}`,
          value: '',
          style: { marginBottom: '10px', marginTop: '10px', borderBottom: '1px dotted #ddd', justifyContent: 'center' },
        });
      }
      // Review all transactions & calculate total
      let trxtotal = 0;
      const tooltipElems: WhisperTooltip[] = dataRows.map(row => {
        trxtotal += row.amount;
        return {
          title: '# ' + row.num,
          value: formatAmount(row.amount),
        };
      });

      // We will only display a maximum of 6 transactions
      if (tooltipElems.length > 6) {
        const remainingCount = tooltipElems.length - 6;
        tooltipElems.splice(6);
        tooltipElems.push({
          title: `${remainingCount} more transaction${remainingCount > 1 ? 's' : ''}`,
          value: '',
          style: { opacity: 0.5, justifyContent: 'center' },
        });
      }

      // Add total if there is moore than one transaction
      if (transactions > 1) {
        tooltipElems.push({
          title: `Total`,
          value: formatAmount(trxtotal),
          style: { marginTop: '10px', borderTop: '1px solid #ddd', marginBottom: '10px' },
        });
      }
      tooltipData.push(...tooltipElems);
      const tooltip: JSX.Element | string = formatWhisperTooltip(tooltipData);

      return (
        <Whisper placement="top" trigger="hover" delayOpen={600} speaker={<Tooltip>{tooltip}</Tooltip>}>
          {children}
        </Whisper>
      );
    }
    return children;
  };

  const flaggedCell =
    capabilities.flaggedTransactions && trxFlagged && trxFlagged.length > 0 ? trxFlagged[0].status : null;
  const flaggedTransactions = flaggedCell && trxFlagged[0].type === 'trx';
  const flaggedCellTooltip = flaggedCell
    ? formatWhisperTooltip([
        {
          title: flaggedCell,
          value: '',
          style: { textTransform: 'uppercase', fontSize: '10px', textAlign: 'center', paddingTop: '5px' },
        },
        { title: trxFlagged[0].account, value: '', style: { fontWeight: 'bold' } },
        { title: trxFlagged[0].type === 'trx' ? 'Flagged Transaction' : 'Flagged Cell', value: '' },
        { title: 'Value', value: formatAmount(trxFlagged[0].amount), style: { paddingBottom: '5px' } },
      ])
    : '';

  const handleClick = async (e: React.MouseEvent, cellData: Amounts) => {
    e.preventDefault();
    e.stopPropagation();
    if (!capabilities.flaggedTransactions) return;
    const trxModalData = await trxModalPipe(cellData, currentLocation);
    setActions({ ...actions, trxModal: true, trxModalData });
  };

  return (
    <div
      className={classNames(prefix('KS-report-cell'), {
        'extra-height': vizHelpers.isBigHeader,
        smoothing: mustSmooth,
        total: vizHelpers.isTotal,
      })}
      data-cell-id={cellID}
    >
      {!vizHelpers.noValuesToShow && (
        <>
          {flaggedCell && (
            <Whisper placement="top" trigger="hover" delayOpen={400} speaker={<Tooltip>{flaggedCellTooltip}</Tooltip>}>
              <div
                className={classNames(prefix('flagged'), {
                  open: flaggedCell === 'open',
                  inProgress: flaggedCell === 'inProgress',
                  closed: flaggedCell === 'closed',
                })}
                onClick={e => handleClick(e, cellData)}
              ></div>
            </Whisper>
          )}
          {capabilities.transactions &&
            filters.transactions &&
            formatTransactions(
              <div
                className={classNames(prefix('transactions'), {
                  anomaly: showtrxAnomaly,
                  ['anomaly-positive']: showtrxAnomaly && trxAnomalyValue > 0,
                  flagged: flaggedTransactions,
                  ['flagged-closed']: flaggedTransactions && flaggedCell === 'closed',
                  ['flagged-in-progress']:
                    capabilities.flaggedTransactions && flaggedTransactions && flaggedCell === 'inProgress',
                })}
                data-cell-id={cellID}
                onClick={e => handleClick(e, cellData)}
              >
                {transactions && transactions > 0 ? (
                  <div>●</div>
                ) : (
                  <Whisper
                    placement="top"
                    trigger="hover"
                    delayOpen={400}
                    speaker={
                      <Tooltip>{flaggedCell ? flaggedCellTooltip : 'Request info or report this account'}</Tooltip>
                    }
                  >
                    {capabilities.flaggedTransactions && flaggedCell ? (
                      <div className={prefix('transactions-flag')}>
                        <IconFlagFilled />
                      </div>
                    ) : (
                      <div className={prefix('transactions-request')}>
                        <IconRequest />
                      </div>
                    )}
                  </Whisper>
                )}
              </div>
            )}
          {filters.actuals &&
            formatActualsBlock(
              <div
                className={classNames(prefix('actual-container'), {
                  percentage: filters.percentage && filters.money,
                  anomaly: showAnomaly,
                  ['anomaly-positive']: showAnomaly && anomalyValue > 0,
                })}
                data-cell-id={cellID}
                onClick={e => !capabilities.transactions && handleClick(e, cellData)}
              >
                {capabilities.money && filters.money && formatComposedAmount()}
                {capabilities.percentage && filters.percentage && FormatPercentage(composedPercent, vizHelpers.isTotal)}
              </div>
            )}

          {capabilities.budget && budgetAvailable && filters.budget && (
            <div
              className={classNames(prefix('actual-container'), {
                percentage: filters.percentage && filters.money,
                budget: true,
                'no-separator': !filters.actuals,
              })}
              data-cell-id={cellID}
            >
              {capabilities.money && filters.money && formatComposedAmount(budget)}
              {capabilities.percentage && filters.percentage && FormatPercentage(budgetPercent)}
            </div>
          )}
          {capabilities.budget &&
            budgetAvailable &&
            filters.budget &&
            filters.actuals &&
            filters.variance &&
            formatVariance(
              <div
                className={classNames(prefix('variance-container'), {
                  percentage: filters.percentage && filters.money,
                  anomaly: varianceAnalisys && varianceAnalisys.tendency === 'negative',
                  ['anomaly-positive']: varianceAnalisys && varianceAnalisys.tendency === 'positive',
                })}
                data-cell-id={cellID}
              >
                {capabilities.money && filters.money && formatComposedAmount(difference, true)}
                {capabilities.percentage && filters.percentage && FormatPercentage(variance)}
              </div>
            )}
        </>
      )}
    </div>
  );
};
export default FormatReportValues;
