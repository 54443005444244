import { color } from 'echarts';
import { getCSSVariable } from '../../NTMiniMap/NTMiniMapUtils';
import { ChartGroup, ChartProps } from '../types';
import { themeColors } from './chartCFG';

// Helper functions
export const colorBlock = (type: ChartGroup, value: number, fill = false, selected = false, hovered = false) => {
  const theme = themeColors();
  const colorType = fill ? 'fill' : 'line';
  const colorState = selected && fill ? 'selected' : hovered ? 'hovered' : value >= 0 ? 'positive' : 'negative';
  return theme[type][colorType][colorState as keyof (typeof theme)[typeof type][typeof colorType]];
};
export const calculateNiceScale = (min: number, max: number, maxTicks: number) => {
  const range = niceNum(max - min, false);
  const tickSpacing = niceNum(range / (maxTicks - 1), true);
  const niceMin = Math.floor(min / tickSpacing) * tickSpacing;
  const niceMax = Math.ceil(max / tickSpacing) * tickSpacing;
  return { min: niceMin, max: niceMax, tickSpacing };
};

const niceNum = (range: number, round: boolean) => {
  const exponent = Math.floor(Math.log10(range));
  const fraction = range / Math.pow(10, exponent);
  let niceFraction;

  if (round) {
    if (fraction < 1.5) niceFraction = 1;
    else if (fraction < 3) niceFraction = 2;
    else if (fraction < 7) niceFraction = 5;
    else niceFraction = 10;
  } else {
    if (fraction <= 1) niceFraction = 1;
    else if (fraction <= 2) niceFraction = 2;
    else if (fraction <= 5) niceFraction = 5;
    else niceFraction = 10;
  }

  return niceFraction * Math.pow(10, exponent);
};

export const checkIfallValuesAreZero = (chartData: ChartProps, groups: ChartGroup[]) => {
  if (!chartData || !chartData.columns || !chartData.columns.length) return true;
  const testGroups = groups.map(group => group.toLowerCase());
  return chartData.columns.every(col =>
    Object.values(col.data).every(type => {
      return testGroups.every(group => {
        const value = type[group as keyof typeof type];
        return value === 0;
      });
    })
  );
};

export function generateColorShades(baseColor: string, count: number): string[] {
  // Convert hex to HSL
  const hsl = hexToHSL(baseColor);
  // Adjust the lightness based on the count
  const maxLightness = 95; // Maximum lightness value
  const rangeAmount = 30; // Adjust this value to control the range of lightness
  const step = count > 1 ? rangeAmount / (count - 1) : rangeAmount; // Using 80 as the range for lightness (40 to 80)

  if (count === 1) {
    return [`rgb(${hslToRgb(hsl.h, hsl.s, maxLightness - rangeAmount).join(', ')})`];
  }
  return Array.from({ length: count }, (_, i) => {
    const lightness = Math.max(maxLightness - rangeAmount, Math.min(maxLightness, maxLightness - i * step)); // Ensure lightness stays between 40 and 80
    return `rgb(${hslToRgb(hsl.h, hsl.s, lightness).join(', ')})`;
  }).reverse();
}

const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
  // Remove the # if present
  hex = hex.replace(/^#/, '');

  // Parse the hex values
  const r = parseInt(hex.slice(0, 2), 16) / 255;
  const g = parseInt(hex.slice(2, 4), 16) / 255;
  const b = parseInt(hex.slice(4, 6), 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  return { h: h * 360, s: s * 100, l: l * 100 };
};

function hslToRgb(h: number, s: number, l: number): [number, number, number] {
  h /= 360;
  s /= 100;
  l /= 100;
  let r, g, b;

  if (s === 0) {
    r = g = b = l;
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}
