import { useMutation } from '@tanstack/react-query';
import { PostUploadBudgets } from 'domain/usecases/budgets';

import { FIVE_SECONDS, TOAST_ERROR, TOAST_SUCCESS, showToast } from 'lib/utils';

import { makeRemoteUploadBudget } from 'main/factories/usecases';
import { useNavigate, useParams } from 'react-router-dom';

export const useUploadBudgets = () => {
  const { locationId: currentLocationId } = useParams();
  const navigateTo = useNavigate();
  return useMutation({
    mutationFn: (uploadBudgetData: PostUploadBudgets.Params) => {
      const service = makeRemoteUploadBudget();
      return service.postUploadBudgets(uploadBudgetData);
    },
    onError: () => {
      showToast('Error while uploading budget', TOAST_ERROR, FIVE_SECONDS);
    },
    onSuccess: () => {
      showToast('Budget created successfully', TOAST_SUCCESS, FIVE_SECONDS);
      navigateTo(
        `/app/location/${currentLocationId}/bookkeeper?activetab=Budgets&modalBudgetOpen=false&startDate=2023-01-01`
      );
    },
  });
};
