import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getTooltipContent } from './NTMiniMapUtils';
import { useNTContext } from '../NTContext';
import { useNTUtilsContext } from '../NTUtilsContext';
import { useNTSelectionContext } from '../NTSelectionContext';
import { Capabilities } from 'presentation/components/KSExport/reportNTParser/types';

interface HighlightedCell {
  row: number;
  col: number;
  type: 'positive' | 'negative' | 'request' | 'variance';
}

export interface TableMinimapProps {
  rawData?: any;
}

interface TooltipInfo {
  content: JSX.Element;
  x: number;
  y: number;
}

type parsedDataProps = {
  height: number;
  gap: number;
  rows: parsedDataRowProps[];
  columns: { title: string; isFixed?: boolean; isMainColumn?: boolean }[];
};

type parsedDataRowProps = {
  id: string;
  index: number;
  title: string;
  isBigHeader: boolean;
  isHeader: boolean;
  isTotal: boolean;
  noValues: boolean;
  cells: parsedDataCellProps[];
  height: number;
};

type parsedDataCellProps = {
  value: any;
  amount: number;
  anomaly: any;
  level: number;
  smooth: boolean;
  total: boolean;
  flagged: string | null;
};

type heightsProps = {
  bigs: number;
  headers: number;
  totals: number;
  rows: number;
  gap: number;
};

export type stylesProps = {
  maxCellWidth: number;
  maxMiniMapWidth: number;
  firstColumnWidth: number;
  positiveColor: string;
  negativeColor: string;
  smoothColor: string;
  flagOpenColor: string;
  flagClosedColor: string;
  flagInProgressColor: string;
  cellHeight: number;
  headerHeight: number;
  bigHeaderHeight: number;
  rowGap: number;
};

// We parse the data here to make it easier to render the minimap
const parseData = (
  data: any,
  heights: heightsProps,
  reverse = false,
  capabilities: Capabilities,
  trace = false
): parsedDataProps => {
  if (trace) console.log('data', data);
  if (!data || data.columns.length === 0) {
    return { height: 0, columns: [], rows: [], gap: 0 } as parsedDataProps;
  }
  if (trace) console.time('parseData');
  const parsedData: parsedDataProps = { height: 0, columns: [], rows: [], gap: 0 };
  let y = 0;
  let allColumns = data.columns;
  if (reverse) {
    allColumns = [data.columns[0], ...data.columns.slice(1).reverse()];
  }
  parsedData.rows = Array.from({ length: allColumns[0].data.length }, (_, rowIndex) => {
    const cells: parsedDataCellProps[] = allColumns.map((column: any, colIndex: number) => {
      const cell = allColumns[colIndex].data[rowIndex];
      const anomalyIndex = reverse ? allColumns.length - 1 - colIndex : colIndex - 1;
      const anomaly = cell.anomaly?.anomalyScores[anomalyIndex];
      return {
        value: cell,
        anomaly: anomaly,
        amount: cell.amount,
        level: cell.level,
        smooth: !cell.vizHelpers.noValuesToShow && cell.amountAVG !== undefined && cell.amountAVG !== cell.amount,
        total: cell.vizHelpers.isTotal,
        flagged:
          capabilities.flaggedTransactions && cell.trxFlagged && cell.trxFlagged.length > 0
            ? cell.trxFlagged[0].status
            : null,
      };
    });
    const firstCell = allColumns[0].data[rowIndex];
    const thisHeight =
      heights.gap +
      (firstCell.vizHelpers.isBigHeader
        ? heights.bigs
        : firstCell.vizHelpers.isHeader
          ? heights.headers
          : firstCell.vizHelpers.isTotal
            ? heights.totals
            : heights.rows);
    y += thisHeight;
    return {
      id: firstCell.id,
      index: rowIndex,
      title: firstCell.amountTitle,
      isBigHeader: firstCell.vizHelpers.isBigHeader,
      isHeader: firstCell.vizHelpers.isHeader,
      isTotal: firstCell.vizHelpers.isTotal,
      noValues: firstCell.vizHelpers.noValuesToShow,
      cells: cells,
      height: thisHeight,
    };
  });
  parsedData.height = y;
  parsedData.gap = heights.gap;
  parsedData.columns = allColumns.map((column: any, index: number) => {
    return {
      title: column.name,
      isFixed: index === 0,
    };
  });
  if (trace) console.log('parsedData', parsedData);
  if (trace) console.timeEnd('parseData');
  return parsedData;
};

// We are going to create also an allocation map of the cells so we can find them with the tootltip
// We are going to introduce the dependency array to the minimap so we can update it when the filters change
// Search should also highlight the cells in the minimap

export const useNTMiniMapData = (
  hookElement: React.RefObject<HTMLCanvasElement>,
  rawData: TableMinimapProps,
  style: stylesProps,
  trace: boolean = false
) => {
  const {
    maxCellWidth,
    maxMiniMapWidth,
    firstColumnWidth,
    positiveColor,
    negativeColor,
    flagOpenColor,
    flagClosedColor,
    flagInProgressColor,
    smoothColor,
    cellHeight,
    headerHeight,
    bigHeaderHeight,
    rowGap,
  } = style;

  const { filters } = useNTContext();
  const { actions, capabilities } = useNTUtilsContext();
  const { cellStates } = useNTSelectionContext();
  const { hover } = cellStates;

  const parsedData = useMemo(
    () =>
      parseData(
        rawData,
        {
          bigs: bigHeaderHeight,
          headers: headerHeight,
          totals: headerHeight,
          rows: cellHeight,
          gap: rowGap,
        },
        actions.reverseColumns,
        capabilities,
        trace
      ),
    [rawData, bigHeaderHeight, headerHeight, cellHeight, rowGap, actions]
  );

  const { rows, columns, gap, height } = parsedData;

  const cellWidth = Math.min(maxCellWidth, Math.round((maxMiniMapWidth - (columns.length - 2)) / (columns.length - 1)));
  const colGap = cellWidth === maxCellWidth ? 2 : 1;
  const canvasWidth = firstColumnWidth + 2 + (columns.length - 1) * cellWidth + (columns.length - 2) * colGap;
  const canvasHeight = height;

  const drawMinimap = useCallback(
    (ctx: CanvasRenderingContext2D) => {
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      if (trace) console.log('updating minimap drawing');
      let yOffset = 0;
      rows.forEach((row, rowIndex) => {
        const isBigHeader = row.isBigHeader;
        const y = yOffset;

        if (isBigHeader && row.noValues) {
          ctx.fillStyle = '#000000';
          ctx.font = '2px Arial';
          ctx.fillText(row.title, 0, y + 6);
        } else {
          let x = 0;
          row.cells.forEach((cell, colIndex) => {
            const cWidth =
              colIndex === 0 ? (cell.level ? firstColumnWidth - cell.level * 2 : firstColumnWidth) : cellWidth;
            if (capabilities.anomalies && filters.anomalies && cell.anomaly !== undefined && cell.anomaly !== 0) {
              ctx.fillStyle = cell.anomaly > 0 ? positiveColor : negativeColor;
            } else if (filters.smoothing && cell.smooth) {
              ctx.fillStyle = smoothColor;
            } else {
              ctx.fillStyle =
                cell.amount === 0
                  ? '#f3f3f3'
                  : isBigHeader
                    ? '#e0e0e0'
                    : cell.total
                      ? '#d2d2d2'
                      : row.isHeader || colIndex === 0
                        ? '#e2e2e2'
                        : '#e7e7e7';
            }
            if (cell.flagged !== null) {
              ctx.fillStyle =
                cell.flagged === 'open'
                  ? flagOpenColor
                  : cell.flagged === 'closed'
                    ? flagClosedColor
                    : flagInProgressColor;
            }
            ctx.fillRect(colIndex > 0 ? x : cell.level ? x + cell.level * 2 : x, y, cWidth, row.height - gap);
            x += (colIndex === 0 ? firstColumnWidth : cellWidth) + colGap;
          });
        }
        if (row.id === cellStates.hover) {
          ctx.fillStyle = '#89e19c55';
          ctx.fillRect(0, y, canvasWidth, row.height);
        } else if (row.id === cellStates.selected) {
          ctx.fillStyle = '#89e19cAA';
          ctx.fillRect(0, y, canvasWidth, row.height);
        }
        yOffset += row.height;
      });
    },
    [
      rows,
      canvasWidth,
      canvasHeight,
      firstColumnWidth,
      cellWidth,
      colGap,
      positiveColor,
      negativeColor,
      smoothColor,
      flagOpenColor,
      flagInProgressColor,
      flagClosedColor,
      gap,
      filters,
      actions,
      cellStates,
    ]
  );

  useEffect(() => {
    const canvas = hookElement.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    drawMinimap(ctx);
  }, [drawMinimap, hookElement, filters, actions]);

  //Info Tooltip
  /*   const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = hookElement.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const rowIndex = Math.floor(y / (cellHeight + rowGap));
    const colIndex = Math.floor(x / (cellWidth + colGap));

    if (rowIndex >= 0 && rowIndex < columns.length && colIndex >= 0 && colIndex < columns.length) {
      const content = getTooltipContent(columns, rows, rowIndex, colIndex);
      setTooltipInfo({ content, x: event.clientX, y: event.clientY });
    } else {
      setTooltipInfo(null);
    }
  };

  const handleMouseLeave = () => {
    setTooltipInfo(null);
  }; */

  return {
    canvasWidth,
    canvasHeight,
  };
};
