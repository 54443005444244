import React, { useRef, useState } from 'react';
import NTDrawColumn from '../NTLayout/NTDrawColumn';
import NTDrawHeader from '../NTLayout/NTDrawHeader';
import { prefixClass } from 'lib/utils';
import { Column, TransformedData } from '../../KSExport/reportNTParser/types';
import { use } from 'echarts';
const prefix = prefixClass('nt-layout');

const useColumnRenderer = (
  data: TransformedData,
  columnsData: Column[],
  reverseColumns: boolean,
  addTotalRow: boolean,
  addTotalColumn: boolean,
  addDifference: boolean,
  liveReport: number,
  fixFC: boolean,
  fixLC: boolean
) => {
  const localColumns: Column[] = columnsData ? [...columnsData] : [];

  if (addTotalColumn && !addDifference) {
    localColumns.push(data.utilityData.totalColumn);
  } else if (addDifference && !addTotalColumn) {
    localColumns.push(data.utilityData.differenceColumn);
  }

  const renderedColumns: any[] = [];
  const renderedHeaders: any[] = [];
  const columns: number = localColumns.length;
  const lastColumn: number = columns - 1;
  let middleProcessed: boolean = false;
  const middleColumnsStart: number = fixFC ? 1 : 0;
  const middleColumnsEnd: number = fixLC ? columns - 1 : columns;

  /*   console.log('columns', columns, localColumns);
  console.log('total', fixLC); */

  localColumns.forEach((mainColumn: Column, columnIndex: number | undefined) => {
    const fixed = columns > 2 && ((fixFC && columnIndex === 0) || (fixLC && columnIndex === lastColumn));
    if ((fixFC && columnIndex === 0) || (columnIndex === lastColumn && fixLC)) {
      renderedHeaders.push(
        <NTDrawHeader key={`header${columnIndex}`} columnIndex={columnIndex} data={mainColumn} fixed={fixed} />
      );
      renderedColumns.push(
        <NTDrawColumn
          key={`column${columnIndex}`}
          columnIndex={columnIndex}
          title={mainColumn.name}
          columnData={mainColumn.data}
          fixed={fixed}
          totalColumn={mainColumn.total}
        />
      );
    } else if (!middleProcessed) {
      let middleColumns = localColumns.slice(middleColumnsStart, middleColumnsEnd);
      if (reverseColumns) {
        middleColumns = middleColumns.reverse();
      }
      renderedHeaders.push(
        <div
          id="header-middle"
          key={`header${middleColumnsStart}-${middleColumnsEnd}`}
          className={`${prefix('column')} scrollable`}
        >
          {middleColumns.map((column: any, columnIndex: number) => (
            <NTDrawHeader
              key={`middleHeader-${column.name}-${columnIndex}`}
              columnIndex={reverseColumns ? middleColumnsEnd - columnIndex - 1 : columnIndex + middleColumnsStart}
              data={
                localColumns[reverseColumns ? middleColumnsEnd - columnIndex - 1 : columnIndex + middleColumnsStart]
              }
            />
          ))}
        </div>
      );
      renderedColumns.push(
        <div
          id="table-middle"
          key={`column${middleColumnsStart}-${middleColumnsEnd}`}
          className={`${prefix('column')} scrollable`}
        >
          {middleColumns.map((column: any, columnIndex: number) => (
            <NTDrawColumn
              key={`middle-${column.name}-${columnIndex}`}
              liveColumn={column.liveReport}
              columnIndex={columnIndex + middleColumnsStart}
              title={column.name}
              columnData={column.data}
              totalColumn={mainColumn.total}
              compoundColumn={column.compoundColumn}
            />
          ))}
        </div>
      );
      middleProcessed = true;
    }
  });

  return { renderedHeaders, renderedColumns };
};

export default useColumnRenderer;
