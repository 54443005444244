import { IconFileUpload, IconMoneyBudget } from 'components/Icons';
import { useForm } from 'react-hook-form';

import Modal from 'react-modal';
import classNames from 'classnames';
import KSButton from 'presentation/components/KSButton';
import { UploadBudgetPayload } from '@hone-automation/common';

import { useParams } from 'react-router-dom';
import { useUploadBudgets } from 'hooks/budgets';
import Loading from 'components/Loading';

type BookkepperBudgetsModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
};

const BookkepperBudgetsModal = ({ isOpen, onRequestClose }: BookkepperBudgetsModalProps) => {
  const { locationId: currentLocationId } = useParams();

  const { handleSubmit, watch, reset, register, setValue } = useForm<any>({
    defaultValues: {
      uploadedFile: null,
    },
  });
  const { mutate: uploadBudget, isPending } = useUploadBudgets();

  const { uploadedFile } = watch();

  const handleCreateBudget = () => {
    if (!uploadedFile) {
      return;
    }

    const formData = new FormData();
    formData.append('locationId', currentLocationId!);
    formData.append('timeframe', 'Month');
    formData.append('dataType', 'csv');
    formData.append('file', uploadedFile);

    const payload: UploadBudgetPayload = {
      data: '',
      locationId: currentLocationId!,
      timeframe: 'Month',
      dataType: 'csv',
    };

    const reader = new FileReader();
    reader.onload = () => {
      //enconde results base64
      const encoded = btoa(reader.result as string);
      payload.data = encoded;
      uploadBudget(payload, {
        onSuccess: () => {
          onRequestClose();
          reset();
        },
      });
    };

    reader.onerror = () => {
      alert('Failed to read file. Please try again.');
    };

    // Read the file as text
    reader.readAsText(uploadedFile);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setValue('uploadedFile', file);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Example Modal"
      id="budgets-layout-modal"
      className={'budgets-layout__modal'}
    >
      {/* Overlay Loader */}
      {isPending && (
        <div className="budgets-layout__modal__loader-overlay">
          <div className="budgets-layout__modal__loader">
            <Loading />
          </div>
        </div>
      )}
      <div className="budgets-layout__modal__header">
        <h2 className="budgets-layout__modal__header__title">
          <IconMoneyBudget /> New Budget
        </h2>
        <button className="budgets-layout__modal__header__close" onClick={onRequestClose}>
          &times;
        </button>
      </div>

      <div className="budgets-layout__modal__content">
        {/* WIll be keep here for future QBO implementation 
         <div className="budgets-layout__modal__content__form__tabs">
          <div
            className={classNames('budgets-layout__modal__content__form__tabs__tab', {
              active: activeBudgetTab === 'Create',
            })}
            onClick={() => setActiveBudgetTab('Create')}
          >
            <span>Create</span>
          </div>
          <div
            className={classNames('budgets-layout__modal__content__form__tabs__tab', {
              active: activeBudgetTab === 'Import',
            })}
            onClick={() => setActiveBudgetTab('Import')}
          >
            <span>
              Import <IconQbo />
            </span>
          </div>
        </div> */}

        <form onSubmit={handleSubmit(handleCreateBudget)}>
          <div className="budgets-layout__modal__content__form">
            <div className={classNames('budgets-layout__modal__content__form__section', 'align-start', 'flex-col')}>
              <div className="budgets-layout__modal__content__form__section__row">
                <p className="budgets-layout__modal__content__form__section__paragraph">Upload your template file</p>
              </div>
              {!uploadedFile && (
                <label
                  htmlFor="file-upload"
                  className={classNames('budgets-layout__modal__content__form__section__file_upload', {
                    uploaded: uploadedFile && uploadedFile?.name,
                  })}
                >
                  <span className="budgets-layout__modal__content__form__section__file_upload_text ">
                    Select a file (.csv) to upload
                  </span>

                  <IconFileUpload />
                </label>
              )}

              {uploadedFile && uploadedFile?.name && (
                <label
                  className={classNames('budgets-layout__modal__content__form__section__file_upload', {
                    uploaded: uploadedFile && uploadedFile?.name,
                  })}
                >
                  <span className="budgets-layout__modal__content__form__section__file_upload_text ">
                    {uploadedFile?.name}
                  </span>

                  <div
                    onClick={() => {
                      setValue('uploadedFile', null);
                    }}
                    className="budgets-layout__modal__content__form__section__file_upload__close"
                  >
                    &times;
                  </div>
                </label>
              )}
              <input
                id="file-upload"
                {...register('uploadedFile', { required: true })}
                type="file"
                accept=".csv"
                className="hidden"
                onChange={handleFileChange}
              />
              {uploadedFile && uploadedFile?.name ? (
                <p>
                  If you click on <b>create</b> your budget would be created
                </p>
              ) : (
                <>
                  <p>Once the file will be uploaded you will we able to review the budget.</p>
                </>
              )}
            </div>
            <div className="budgets-layout__modal__content__form__section fixed-bottom">
              <KSButton label="Create Budget" onClick={handleCreateBudget} disabled={!uploadedFile} type="submit" />
            </div>
          </div>
        </form>
      </div>
      {/* future use import form qbo 
      {activeBudgetTab === 'Import' && (
        <form onSubmit={handleSubmit(handleCreateBudget)}>
          <div className="budgets-layout__modal__content__form">
            <div className="budgets-layout__modal__content__form__section">
              <span
                className={classNames('budgets-layout__modal__content__form__section__number', {
                  active: period !== '--',
                })}
              >
                1
              </span>
              <p className="budgets-layout__modal__content__form__section__paragraph">
                Select the budget you want to import from the available ones.
              </p>
            </div>
            <div className="budgets-layout__modal__content__form__section justify-start">
              <div className="budgets-layout__modal__content__form__section__title">Available budgets</div>
              <KSDropdown
                options={periods.map(period => ({ label: period, value: period }))}
                value={{
                  label: period,
                  value: period,
                }}
                onSelect={option => {
                  setValue('period', option.value);
                }}
                placeholder="Monthly"
                theme="grey"
              />
            </div>
            <div
              className={classNames('budgets-layout__modal__content__form__section', 'align-start', 'flex-col', {
                'disabled-section': disabledSecondSection,
              })}
            >
              <div className="budgets-layout__modal__content__form__section__row">
                <span className="budgets-layout__modal__content__form__section__number">2</span>
                <p className="budgets-layout__modal__content__form__section__paragraph">Requesting Budget</p>
              </div>
              <div className="budgets-layout__modal__content__form__section__row center">
                <KSButton
                  label="Request Budget From "
                  onClick={handleRequestQboBudget}
                  type="button"
                  icon={<IconQbo />}
                />
              </div>
              <p>We will request your budget from QBO.</p>
            </div>
            <div className="budgets-layout__modal__content__form__section top-margin">
              <KSButton label="Import" disabled={!uploadedFile} type="submit" />
            </div>
          </div>
        </form>
      )} */}
    </Modal>
  );
};

export default BookkepperBudgetsModal;
