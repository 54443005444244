import { useState, useEffect } from 'react';
import { DataCellState } from 'domain/models';
import { initialDataCellState } from 'hooks/useActiveReportStore';
import { useNTUtilsContext } from '../NTUtilsContext';

export const useModalState = () => {
  const { actions, setActions } = useNTUtilsContext();
  const [modalState, setModalState] = useState<DataCellState>({
    ...initialDataCellState,
    modalOpen: true,
  });

  const handleClose = () => {
    setModalState({ ...modalState, modalOpen: actions.trxModal });
    setActions({
      ...actions,
      trxModal: false,
    });
  };

  useEffect(() => {
    setModalState({ ...actions.trxModalData, modalOpen: actions.trxModal });
  }, [actions]);

  return {
    modalState,
    handleClose,
    showModal: actions.trxModal,
  };
};
