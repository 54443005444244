import { IconAdd, IconDeleteOutline, IconEdit } from 'components/Icons';
import { Metrics } from 'domain/models';
import { useBookkeeperReport } from 'hooks/useBookkeeper';

import { useLocationsStore } from 'hooks/useLocationsStore';
import { useEffect } from 'react';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import Modal from 'react-modal';
import BookkeeperMetricForm from './BookkeeperMetricForm';
import { formatDate } from 'lib/utils';
import { ConfirmModal } from 'presentation/components';

export const modalStyle = {
  content: {
    width: '600px',
    top: '50%',
    transform: 'translateY(-50%)',
    height: 'fit-content',
    margin: '0 auto',
  },
};

export default function BookkeeperMetrics(): JSX.Element {
  const {
    fetchBookkeeperMetrics,
    bookkeeperMetrics,
    loadingBookkeeper,
    showNewMetricModal,
    showConfirmModal,
    setShowConfirmModal,
    setShowNewMetric,
    handleMetric,
    metric,
    deleteMetric,
  } = useBookkeeperReport();
  const { currentLocationId } = useLocationsStore();

  useEffect(() => {
    if (currentLocationId) {
      fetchBookkeeperMetrics(currentLocationId);
    }
  }, [currentLocationId, fetchBookkeeperMetrics, showNewMetricModal]);

  const handleClickMetric = (metric: Metrics) => {
    handleMetric(metric);
    setShowNewMetric(true);
  };

  const handleClickDeleteMetric = (metric: Metrics) => {
    handleMetric(metric);
    setShowConfirmModal(true);
  };

  const handleDeleteMetric = () => {
    metric !== undefined && metric?.id && currentLocationId && deleteMetric(metric.id, currentLocationId);
  };

  return (
    <div className="bookkeeper-tab-container">
      <Modal
        id="metric-modal-form"
        style={modalStyle}
        isOpen={showNewMetricModal}
        onRequestClose={() => setShowNewMetric(!showNewMetricModal)}
      >
        <BookkeeperMetricForm />
      </Modal>
      <Modal
        id="confirm-modal-form"
        style={modalStyle}
        isOpen={showConfirmModal}
        onRequestClose={() => setShowConfirmModal(!showConfirmModal)}
      >
        <ConfirmModal
          onClose={() => setShowConfirmModal(!showConfirmModal)}
          onConfirm={handleDeleteMetric}
          title={
            <>
              Delete Metric <b>{metric?.configuration?.title}</b>
            </>
          }
        >
          <div>Are you sure? Hit confirm to delete</div>
        </ConfirmModal>
      </Modal>
      <button
        className="bookkeeper-metrics-btn"
        title={'add new metric'}
        onClick={() => {
          handleMetric(undefined);
          setShowNewMetric(!showNewMetricModal);
        }}
      >
        <IconAdd />
      </button>
      <div className="hone-report-table">
        <Table
          height={700}
          hover
          data={bookkeeperMetrics}
          rowHeight={50}
          affixHeader
          affixHorizontalScrollbar
          loading={loadingBookkeeper}
        >
          <Column width={40}>
            <HeaderCell>{''}</HeaderCell>
            <Cell>
              {rowData => {
                return (
                  <div className="validation-report--table-icon">
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    {<IconEdit onClick={() => handleClickMetric(rowData)} width={18} height={18} />}
                  </div>
                );
              }}
            </Cell>
          </Column>
          <Column width={40}>
            <HeaderCell>{''}</HeaderCell>
            <Cell>
              {rowData => {
                return (
                  <div className="validation-report--table-icon">
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    {<IconDeleteOutline onClick={() => handleClickDeleteMetric(rowData)} width={18} height={18} />}
                  </div>
                );
              }}
            </Cell>
          </Column>
          <Column width={400}>
            <HeaderCell align={'center'}>ID</HeaderCell>
            <Cell>
              {rowData => {
                return <div className="validation-report--table-icon">{rowData.id}</div>;
              }}
            </Cell>
          </Column>
          <Column width={350}>
            <HeaderCell>Title</HeaderCell>
            <Cell>
              {rowData => {
                return <div>{rowData.configuration?.title}</div>;
              }}
            </Cell>
          </Column>
          <Column width={350}>
            <HeaderCell>Value</HeaderCell>
            <Cell>
              {rowData => {
                return <div>{rowData.configuration?.value?.section}</div>;
              }}
            </Cell>
          </Column>
          <Column width={350}>
            <HeaderCell>Percent Title</HeaderCell>
            <Cell>
              {rowData => {
                return <div>{rowData.configuration?.percentTitle}</div>;
              }}
            </Cell>
          </Column>
          <Column width={350}>
            <HeaderCell>Created At</HeaderCell>
            <Cell>
              {rowData => {
                return <div>{rowData?.createdAt && formatDate(rowData?.createdAt, 'MM/dd/yyyy h:mm:ss aaa')}</div>;
              }}
            </Cell>
          </Column>
          <Column width={350}>
            <HeaderCell>Updated At</HeaderCell>
            <Cell>
              {rowData => {
                return <div>{rowData?.updatedAt && formatDate(rowData?.updatedAt, 'MM/dd/yyyy h:mm:ss aaa')}</div>;
              }}
            </Cell>
          </Column>
        </Table>
      </div>
    </div>
  );
}
