import React from 'react';

import { PageHeader } from 'presentation/components';
import { IconNbAsk } from 'components/Icons';
import AskCustomerTable from 'presentation/components/AskCustomer/Table';
import useLocationRouting from 'hooks/useLocationRouting';
import { UIAskRequestsTitles, UIFlaggedTransaction } from 'types/askcustomer';
import { useCreateFlagTransactionCommentMutation, useFlaggedTransactions } from 'hooks/flaggedTransactions';
import { shallow } from 'zustand/shallow';
import { AskRequestStatus } from '@hone-automation/common';
import { useQueryState } from 'hooks/useQueryState';
import { useAuthContext } from 'context/useAuthContext';
import { useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import KSHeader from '../../components/KSHeader';

function AskRequests() {
  const { locationId } = useParams();
  const [activeTab] = useQueryState<AskRequestStatus>('activeTab', 'Open');

  const { user } = useAuthContext();
  useLocationRouting(false);

  const { activeFlaggedTransaction, setActiveTransaction } = useFlaggedTransactions(
    state => ({
      activeFlaggedTransaction: state.activeFlaggedTransaction,
      setActiveTransaction: state.setActiveTransaction,
    }),
    shallow
  );

  const createCommentMutation = useCreateFlagTransactionCommentMutation(activeTab, locationId!, setActiveTransaction);

  const handleNewComment = async (comment: string, activeTransaction: UIFlaggedTransaction) => {
    createCommentMutation.mutate({ comment, userId: user?.uid, locationId, activeTransaction });
  };

  return (
    // TODO rename report view
    <div className="report-view bg-gray">
      <KSHeader />
      <PageHeader icon={<IconNbAsk />} pageTitle={UIAskRequestsTitles.ASK_REQUESTS} />
      <div className="report-view-container no-padding no-border-radius mobile fixed-width no-scroll">
        <AskCustomerTable
          activeTransaction={activeFlaggedTransaction}
          onSelectActiveTransaction={setActiveTransaction}
          transactions={[]}
          onNewCommentSubmit={handleNewComment}
        />
      </div>
    </div>
  );
}

export default AskRequests;
