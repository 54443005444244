import React, { createContext, useContext, useState } from 'react';

type scrollChange = {
  amount: number;
  axis: 'x' | 'y';
  browser?: boolean;
};

interface ScrollState {
  horizontalScroll: number;
  horizontalScrollPercentage: number;
  cBrowserInput: number;
  visibleAreaOfScrollHorizontal: number;
  verticalScroll: number;
  verticalScrollPercentage: number;
  rBrowserInput: number;
  visibleAreaOfScrollVertical: number;
  scrollAreaWidth: number;
  scrollAreaHeight: number;
  columnBrowserVisible: boolean;
  minimapVisible: boolean;
}

interface ScrollContextType {
  isScrolling: boolean;
  setIsScrolling: React.Dispatch<React.SetStateAction<boolean>>;
  scrollState: ScrollState;
  setScrollState: React.Dispatch<React.SetStateAction<ScrollState>>;
  scrollChange: scrollChange;
  setScrollChange: React.Dispatch<React.SetStateAction<scrollChange>>;
  refreshBrowsers: boolean;
  setRefreshBrowsers: React.Dispatch<React.SetStateAction<boolean>>;
}

const NTScrollContext = createContext<ScrollContextType | undefined>(undefined);

export const NTScrollProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [scrollState, setScrollState] = useState<ScrollState>({
    horizontalScroll: 0,
    horizontalScrollPercentage: 0,
    visibleAreaOfScrollHorizontal: 0,
    cBrowserInput: 0,
    verticalScroll: 0,
    verticalScrollPercentage: 0,
    visibleAreaOfScrollVertical: 0,
    rBrowserInput: 0,
    scrollAreaWidth: 0,
    scrollAreaHeight: 0,
    columnBrowserVisible: false,
    minimapVisible: false,
  });

  const [isScrolling, setIsScrolling] = useState(false);

  const [scrollChange, setScrollChange] = useState<scrollChange>({
    amount: 0,
    axis: 'x',
    browser: false,
  });
  const [refreshBrowsers, setRefreshBrowsers] = useState(false);

  return (
    <NTScrollContext.Provider
      value={{
        scrollState,
        setScrollState,
        scrollChange,
        setScrollChange,
        isScrolling,
        setIsScrolling,
        refreshBrowsers,
        setRefreshBrowsers,
      }}
    >
      {children}
    </NTScrollContext.Provider>
  );
};

export const useNTScroll = () => {
  const context = useContext(NTScrollContext);
  if (context === undefined) {
    throw new Error('useNTScroll must be used within a NTScrollProvider');
  }
  return context;
};
