import { ChartContext, TooltipAreas, ChartDataColumn, TooltipZone } from '../../types';
import { colorBlock } from '../chartUtils';

export function drawLineChart(props: ChartContext) {
  const { data: chartData, body, structure, groups, ctx, hoveredPeriod } = props;
  const { margin, groupWidth, groupGap, chartHeight } = body;
  const { yPercentScale, zeroPercentLineY } = structure;
  if (!ctx || !chartData) return;

  const tooltipAreas: TooltipAreas[] = [];
  chartData.columns.forEach((column: ChartDataColumn, index: number) => {
    const x = margin.left + index * (groupWidth + groupGap);
    const colors = [];
    const radius = 5;
    const hoverArea = radius * 4;
    groups.forEach((type, typeIndex) => {
      const denominator = yPercentScale.max - yPercentScale.min;
      const lastValue = index < chartData.columns.length - 1;
      const percentValue = column.data.percent[type.toLowerCase() as keyof typeof column.data.percent];

      const percentEndValue = lastValue
        ? chartData.columns[index + 1].data.percent[type.toLowerCase() as keyof typeof column.data.percent]
        : percentValue;

      const actualStartY = zeroPercentLineY - (percentValue / denominator) * chartHeight;
      const actualEndY = lastValue ? zeroPercentLineY - (percentEndValue / denominator) * chartHeight : actualStartY;

      const actualStartX = x + groupWidth / 2; //groups.length > 1 ? barWidth / 2 + barWidth * typeIndex :
      const actualEndX = lastValue ? actualStartX + groupWidth + groupGap : actualStartX;

      // Calculate the intersection point of the line with the y-axis if the line moves from positive to negative or vice versa
      const intersectX = actualStartX + (percentValue * (actualEndX - actualStartX)) / (percentValue - percentEndValue);

      const ccolor = colorBlock(type, percentValue);

      // We should not connect the last point to the current point if current point is 0 or null
      if (percentValue !== 0 && percentEndValue !== 0) {
        ctx.strokeStyle = ccolor;
        ctx.beginPath();
        ctx.setLineDash([0, 0]); // Set dotted line pattern
        ctx.lineWidth = 2; // Set line width to 2 pixels

        ctx.moveTo(actualStartX, actualStartY);
        // Draw line from start point to intersection point if the line moves from positive to negative or vice versa
        if ((percentValue < 0 && percentEndValue >= 0) || (percentValue >= 0 && percentEndValue < 0)) {
          ctx.lineTo(intersectX, zeroPercentLineY);
          ctx.stroke();
          ctx.strokeStyle = colorBlock(type, percentValue < 0 ? 1 : -1);
          ctx.beginPath();
          ctx.moveTo(intersectX, zeroPercentLineY);
        }
        ctx.lineTo(actualEndX, actualEndY);
        ctx.stroke();
      }
      // Draw circle at start point
      if (percentValue !== 0) {
        ctx.beginPath();
        ctx.setLineDash([]); // Reset line dash
        ctx.arc(actualStartX, actualStartY, radius, 0, 2 * Math.PI);
        ctx.fillStyle = ccolor;
        ctx.fill();

        if (hoveredPeriod === index) {
          ctx.beginPath();
          ctx.setLineDash([]); // Reset line dash
          ctx.arc(actualStartX, actualStartY, radius * 2, 0, 2 * Math.PI);
          ctx.fillStyle = colorBlock(type, percentValue, true, false, true);
          ctx.fill();
        }

        tooltipAreas.push({
          rect: {
            x: actualStartX - hoverArea / 2,
            y: actualStartY - hoverArea / 2,
            width: hoverArea,
            height: hoverArea,
            fill: ccolor,
          },
          title: column.name,
          category: type,
          period: index,
          measures: [
            {
              name: type,
              value: percentValue,
              unit: '%',
            },
          ],
        });
      }
    });
  });

  const tooltipZone: TooltipZone = {
    title: `${chartData.name}`,
    glCode: chartData.glCode,
    areas: tooltipAreas,
  };
  //console.log(tooltipZone);
  return tooltipZone;
}
