import { ChartContext, TooltipAreas, ChartDataColumn, TooltipZone } from '../../types';
import { colorBlock } from '../chartUtils';

export function drawBars(props: ChartContext) {
  const { data: chartData, body, structure, groups, ctx, selectedPeriod, hoveredPeriod } = props;
  const { margin, groupWidth, groupGap, barWidth, barGap, chartHeight } = body;
  const { yMoneyScale, zeroLineY } = structure;

  if (!ctx || !chartData) return;

  const tooltipAreas: TooltipAreas[] = [];
  chartData.columns.forEach((column: ChartDataColumn, index: number) => {
    const x = margin.left + index * (groupWidth + groupGap);
    groups.forEach((type, typeIndex) => {
      const value = column.data.money[type.toLowerCase() as keyof typeof column.data.money];
      const barHeight = (Math.abs(value) / (yMoneyScale.max - yMoneyScale.min)) * chartHeight;
      const barX = x + typeIndex * (barWidth + barGap);
      const barY = value >= 0 ? zeroLineY - barHeight : zeroLineY;
      const shouldBeHighlighted = index === selectedPeriod && chartData.isParent;
      ctx.fillStyle = colorBlock(type, value, true, shouldBeHighlighted, hoveredPeriod === index);
      ctx.fillRect(barX, barY, barWidth, barHeight);

      tooltipAreas.push({
        rect: {
          x: barX,
          y: barY,
          width: barWidth,
          height: barHeight,
          fill: colorBlock(type, value, true, shouldBeHighlighted),
        },
        title: column.name,
        category: type,
        periodLink: chartData.isParent,
        period: index,
        measures: [
          {
            name: type,
            value: value,
            unit: '$',
          },
        ],
      });
    });
  });
  const tooltipZone: TooltipZone = {
    title: `${chartData.name}`,
    glCode: chartData.glCode,
    areas: tooltipAreas,
  };
  //console.log(tooltipZone);
  return tooltipZone;
}
