import React, { useRef, useEffect, useState, useMemo } from 'react';
import './NTChart.scss';
import { prefixClass } from 'lib/utils';
import { useNTSelectionContext } from '../NTSelectionContext';
import { useNTContext } from '../NTContext';
import { useNTUtilsContext } from '../NTUtilsContext';
import { TransformedData } from '../../KSExport/reportNTParser/types';
import NTChartLegend from './NTChartLegend';
import { debounce } from 'lodash';

import { ChartDataColumn, ChartGroup, ChartProps, ChartFilters, ChartDimensions } from './types';
import ChartBlock from './ChartBlock';
import classNames from 'classnames';

type NTChartProps = {
  data?: ChartProps;
  rawData: TransformedData | null;
  height: number;
};

const NTChart = ({ data, rawData, height }: NTChartProps) => {
  // Imports from Context
  const { cellStates } = useNTSelectionContext();
  const { actions } = useNTUtilsContext();
  const { filters } = useNTContext();
  // Refs
  const containerRef = useRef<HTMLDivElement>(null);
  // Local state
  const [dimensions, setDimensions] = useState<ChartDimensions | null>(null);
  const [chartData, setChartData] = useState<ChartProps | null>(null);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [reversed, setReversed] = useState(actions.reverseColumns);
  const [glAccount, setGlAccount] = useState<string | null>(null);
  const [chartFilters, setChartFilters] = useState<ChartFilters>({
    groups: [],
    money: filters.money,
    percent: filters.percentage,
  });

  // Constants
  const marginHorizontal = parseInt(
    getComputedStyle(document.documentElement).getPropertyValue('--ks-chart-margin-horizontal')
  );
  const marginVertical = parseInt(
    getComputedStyle(document.documentElement).getPropertyValue('--ks-chart-margin-vertical')
  );

  const prefix = prefixClass('chart');

  // Debouncing the drawChart functions to avoid performance issues when resizing from the resize handle
  const updateDimensions = debounce(
    () => {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect();
        setDimensions({ width: width - marginHorizontal * 2, height: height - marginVertical * 2 });
      }
    },
    16,
    { leading: true, trailing: true }
  );

  // Handling the initialization of the scrollbar and set the observer so we can adapt to changes in the size of the scrollbar
  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      updateDimensions();
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (!rawData || !rawData.utilityData.chartData) return;

    const rData = rawData.utilityData.chartData;
    setSelectedRow(cellStates.hover ? cellStates.hover : cellStates.selected ? cellStates.selected : null);

    // Since we are going to reverse columns, we need to create a new chartData object, not a pointer
    setChartData(
      cellStates.hover ? { ...rData[cellStates.hover] } : cellStates.selected ? { ...rData[cellStates.selected] } : null
    );
  }, [cellStates, rawData]);

  useEffect(() => {
    setReversed(actions.reverseColumns);
  }, [actions.reverseColumns]);

  useEffect(() => {
    if (
      !rawData ||
      !rawData.utilityData ||
      !rawData.utilityData.chartData ||
      !chartData ||
      !chartData.columns ||
      !chartData.columns.length ||
      !selectedRow
    )
      return;

    const rData = rawData.utilityData.chartData;

    // We need to bring budgetsAvailable here somehow
    const groups: ChartGroup[] = [];
    if (filters.actuals) groups.push('Actual');
    if (!chartData.isParent && chartData.budget && filters.budget) groups.push('Budget');
    if (!chartData.isParent && filters.actuals && filters.budget && filters.variance) groups.push('Variance');

    // Reverse needs to be calculated properly everytime
    if (reversed) {
      chartData.columns = chartData.columns.reverse();
    } else {
      // if not Reversed we must go back to the original column data
      chartData.columns = [...rData[selectedRow].columns];
    }
    setChartFilters({
      groups: groups,
      money: filters.money,
      percent: !chartData.isParent && filters.percentage,
    });
  }, [rawData, chartData, dimensions, reversed, filters, selectedRow]);

  const MemoizedLegend = useMemo(() => <NTChartLegend data={chartData} />, [chartData]);
  const MemoizedChart = useMemo(() => {
    if (!dimensions || !chartData) return null;
    return <ChartBlock dimensions={dimensions} chartData={chartData} chartFilters={chartFilters} />;
  }, [dimensions, chartData, chartFilters]);

  // Exit conditions before Hooks are called

  /*   if (!rawData || !rawData?.utilityData?.chartData) {
    console.log('(Silent Error) NT[Chart]: No Chart Data');
    return null;
  } */

  return (
    <div ref={containerRef} className={prefix()}>
      <div className={prefix('header')}>{chartData?.info}</div>
      <div className={prefix('concept')}>
        {chartData?.glCode && <div className={prefix('concept-gl-account')}>{chartData?.glCode}</div>}
        {chartData?.total ? chartData?.name?.replace(/total\s*/i, '') : chartData?.name}
        {chartData?.total && <div className={prefix('concept-total')}>Total</div>}
        {chartData?.isParent && <div className={classNames(prefix('concept-total'), 'parent-account')}>Parent</div>}
      </div>
      <div className={prefix('timeframe')}>{chartData?.timeFrame}</div>
      {MemoizedChart}
      {!chartData?.isParent && MemoizedLegend}
    </div>
  );
};
export default NTChart;
