import { prefixClass } from 'lib/utils';
import { TransformedData, Column } from 'presentation/components/KSExport/reportNTParser/types';
import './NTSkeleton.scss';
import { LoadingCloud, LoadingDownload } from 'components/Icons';

const NTSkeleton = ({
  rawData,
  columns,
  rows,
}: {
  rawData?: TransformedData | null;
  columns?: number;
  rows?: number;
}) => {
  /*   if (!rawData) {
    return <div style={{ width: '100%' }}>Loading Data...</div>;
  } */
  const prefix = prefixClass('table-skeleton');
  return (
    <div className={prefix()}>
      {Array.from({ length: columns || 5 }).map((_, index) => {
        return (
          <div key={index} className={prefix('column')}>
            {Array.from({ length: rows || 10 }).map((_, index) => {
              return <div key={index} className={prefix('cell')} />;
            })}
          </div>
        );
      })}
      <div className={prefix('message')}>
        <div className={prefix('message-animated-icon')}>
          <div className={prefix('message-animated-icon-cloud')}>
            <LoadingCloud />
          </div>
          <div className={prefix('message-animated-icon-download')}>
            <LoadingDownload />
          </div>
        </div>
        <div className={prefix('message-animated-icon-text')}>Retrieving Report...</div>
      </div>
    </div>
  );
};

export default NTSkeleton;
