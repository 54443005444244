import './NTSearchAndFilter.scss';
import SFActions from './SFActions';
import SFSearch from './SFSearch';
import { useNTTableContext } from 'presentation/components/NewTable/NTTableContext';

export type ReportType = 'Report' | 'Payable' | 'Other';

type SearchAndFilterProps = {
  searchValue?: string;
  selectedReportType: HoneReportType;
  onSearch?: (search: string) => void;
  onClear?: () => void;
};

const NTSearchAndFilter = ({ searchValue = '', selectedReportType, onSearch, onClear }: SearchAndFilterProps) => {
  const reportType: ReportType =
    selectedReportType === 'Income Statement' || selectedReportType === 'P&L Comparison'
      ? 'Report'
      : selectedReportType === 'AP Aging'
        ? 'Payable'
        : 'Other';
  return (
    <div className="nt-search-and-filter">
      <SFSearch searchValue={searchValue} onSearch={onSearch} onClear={onClear} />
      <SFActions reportType={reportType} selectedReportType={selectedReportType} />
    </div>
  );
};

export default NTSearchAndFilter;
