import './NTChartLegend.scss';
import { prefixClass } from 'lib/utils';
import { ChartGroup, ChartProps } from './types';
import { useNTContext } from '../NTContext';
import classNames from 'classnames';

const NTChartLegend = ({ data }: { data: ChartProps | null }) => {
  const prefix = prefixClass('chart-legend');
  const { filters } = useNTContext();
  // We need to bring budgetsAvailable here somehow
  const groups: ChartGroup[] = [];
  if (!data) return null;
  if (filters.actuals) groups.push('Actual');
  if (data.budget && filters.budget) groups.push('Budget');
  if (data.budget && filters.budget && filters.variance) groups.push('Variance');

  const chartFilters = {
    groups: groups,
    money: filters.money,
    percent: filters.percentage,
  };

  const legendItem = (group: ChartGroup, type: string) => (
    <div className={classNames(prefix('group-item'), `legend-${group.toLocaleLowerCase()}`)}>
      <div
        className={classNames(
          prefix(`group-item-artifact-${type === '%' ? 'dot' : 'box'}`),
          `legend-${group.toLocaleLowerCase()}`
        )}
      ></div>
      <div>{type}</div>
    </div>
  );

  return (
    <div className={prefix()}>
      {groups.map((group, index) => (
        <div key={index} className={prefix('group')}>
          {chartFilters.money && legendItem(group, '$')}
          {chartFilters.percent && legendItem(group, '%')}
          {group}
        </div>
      ))}
    </div>
  );
};

export default NTChartLegend;
