import './NTMiniMap.scss';
import React, { useEffect, useRef, useState } from 'react';
import './NTMiniMap.scss';
import { useNTMiniMapData, stylesProps, TableMinimapProps } from './useNTMiniMapData';
import { prefixClass } from 'lib/utils';
import { useNTScroll } from '../NTScrollContext';
import classNames from 'classnames';
import { anyToHex } from './NTMiniMapUtils';
import { useDragHandler } from '../hooks/useDragHandler';

// Minimap component
const NTMiniMap = ({ rawData }: TableMinimapProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // Color definitions from CSS Variables
  const positiveColor = getComputedStyle(document.documentElement)
    .getPropertyValue('--ks-anomaly-positive-color')
    .trim();
  const negativeColor = getComputedStyle(document.documentElement)
    .getPropertyValue('--ks-anomaly-negative-dark-color')
    .trim();
  const smoothColor = getComputedStyle(document.documentElement).getPropertyValue('--ks-smoothing-color').trim();
  const flagOpenColor = getComputedStyle(document.documentElement).getPropertyValue('--ks-ASK-customer-text').trim();
  const flagInProgressColor = getComputedStyle(document.documentElement)
    .getPropertyValue('--ks-ASK-accountant-text')
    .trim();
  const flagClosedColor = getComputedStyle(document.documentElement).getPropertyValue('--ks-ASK-resolved-text').trim();

  // Setup the styles for the minimap
  const styleSetup: stylesProps = {
    positiveColor: anyToHex(positiveColor, 1),
    negativeColor: anyToHex(negativeColor, 0.5),
    smoothColor: anyToHex(smoothColor, 0.2),
    flagOpenColor: anyToHex(flagOpenColor),
    flagInProgressColor: anyToHex(flagInProgressColor, 1),
    flagClosedColor: anyToHex(flagClosedColor, 1),
    // Widths
    maxCellWidth: 5,
    firstColumnWidth: 16,
    maxMiniMapWidth: 72,
    // Heights
    cellHeight: 4,
    headerHeight: 4,
    bigHeaderHeight: 6,
    rowGap: 1,
  };

  const { setScrollChange } = useNTScroll();

  const { scrollState, setScrollState } = useNTScroll();
  const { minimapVisible, columnBrowserVisible, visibleAreaOfScrollVertical, scrollAreaWidth } = scrollState;
  const { canvasWidth, canvasHeight } = useNTMiniMapData(canvasRef, rawData, styleSetup);
  const [dragging, setDragging] = useState<boolean>(false);
  const prefix = prefixClass('nt-minimap');

  useEffect(() => {
    if (canvasRef.current) {
      document.documentElement.style.setProperty('--ks-mini-map-width', `${canvasWidth}px`);
    }
  }, [canvasWidth, canvasRef]);

  // Then we need to create the window Area overlay over the minimap that displays the amount of the table seen on the viewport
  // That overlay should update when the users scrolls in Y and when the user resizes the window

  const handleDragStart = useDragHandler({
    axis: 'y',
    throttleMs: 16,
    isBrowser: true,
    isMiniMap: true,
  });

  return (
    <div className={classNames(prefix(), { hidden: !minimapVisible })}>
      <div
        id="miniMapArea"
        className={prefix('container')}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          if (dragging) return;
          e.preventDefault();
          e.stopPropagation();
          const rect = e.currentTarget.getBoundingClientRect();
          const clickY = e.clientY - rect.top;
          const clickX = e.clientX - rect.left - 16;
          const visibleAreaHeight = (visibleAreaOfScrollVertical * rect.height) / 2;
          setScrollChange({ axis: 'x', amount: (clickX / (rect.width - 16 - 6)) * scrollAreaWidth });
          setTimeout(() => {
            setScrollChange({ axis: 'y', amount: clickY - visibleAreaHeight, browser: true });
          }, 8);

          /* setScrollState({ ...scrollState, horizontalScrollPercentage: clickX / (rect.width - 16) }); */
          // You can use the heightPercentage value here as needed
        }}
      >
        <canvas
          ref={canvasRef}
          width={canvasWidth}
          height={canvasHeight}

          /*         onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave} */
        />
        <div
          id="miniMapWindow"
          className={prefix('container-window')}
          onMouseDown={e => {
            const windowElement = document.getElementById('miniMapWindow');
            if (!windowElement) return;
            setDragging(true);
            handleDragStart(e, windowElement.offsetTop);
          }}
          onMouseUp={() => {
            setDragging(false);
          }}
        >
          <div
            id="miniMapWindowArea"
            className={classNames(prefix('container-window-area'), { hidden: !columnBrowserVisible })}
          />
        </div>
      </div>
    </div>
  );
};

export default NTMiniMap;

/* {tooltipInfo && (
      <div
        style={{
          position: 'fixed',
          left: tooltipInfo.x + 10,
          top: tooltipInfo.y + 10,
          backgroundColor: 'white',
          border: '1px solid black',
          padding: '5px',
          borderRadius: '3px',
          zIndex: 10,
        }}
      >
        {tooltipInfo.content}
      </div>
    )} */
