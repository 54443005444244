import React, { useRef, useCallback, useEffect, useMemo } from 'react';
import { TransformedData, Column } from '../../KSExport/reportNTParser/types';
import './NTLayout.scss';
import { prefixClass } from 'lib/utils';

import { useNTContext } from '../NTContext';
import { useNTUtilsContext } from '../NTUtilsContext';
import useColumnRenderer from '../hooks/useColumnRenderer';

const TABLE_TYPE_KS = 'KS' as const;
const TABLE_TYPE_CSV = 'CSV' as const;
const TABLE_TYPE_JSON = 'JSON' as const;

type TableType = typeof TABLE_TYPE_KS | typeof TABLE_TYPE_CSV | typeof TABLE_TYPE_JSON;

type NTLayoutProps = {
  data?: TransformedData | any | null;
  type?: TableType;
  fixFC?: boolean;
  fixLC?: boolean;
  searchTerm?: string;
  firstColumnCellisHeader?: boolean;
  parentRef?: React.RefObject<HTMLElement>;
};

const NTLayout = ({
  data,
  type = TABLE_TYPE_KS,
  fixFC = false,
  fixLC = false,
  firstColumnCellisHeader = false,
  parentRef,
  searchTerm,
}: NTLayoutProps) => {
  const { columns, setColumns, filters, budgetAvailable } = useNTContext();
  const { actions, capabilities } = useNTUtilsContext();

  if (!data) {
    return <div>No data Provided</div>;
  }

  let columnsData: Column[] | any[] | null = null;

  switch (type) {
    case TABLE_TYPE_KS:
      columnsData = data.columns ? data.columns : [];
      break;
    case TABLE_TYPE_CSV:
    case TABLE_TYPE_JSON:
      return <div>Not Supported format</div>;
  }

  /**
   * Creates a style element with CSS rules for table rows based on the provided column data.
   * We don't have a physical row. Instead we have series of column cells that conform a Virtual Row
   *
   * @param columnsData - An array of column data objects.
   * @returns A style element with the generated CSS rules.
   */

  const createRowStyles = (columnsData: any[], searchTerm: string | null | undefined) => {
    const style = document.createElement('style');
    const rules = columnsData[0].data
      .map((item: any, index: number) => {
        const rowId = item.id || index;
        return `
                [data-row-id="${rowId}"] { /* base styles */ }
                [data-row-id="${rowId}"]:not(.selected).hovered { background-color: var(--ks-table-highlight); }
                [data-row-id="${rowId}"].hidden { display:none }
              `;
      })
      .join('\n');
    const searchRule = searchTerm
      ? `[data-row-id]:not([data-row-id*="${searchTerm.toLowerCase()}"]) { display: none; }`
      : '';
    style.textContent = rules + searchRule;
    return style;
  };

  const applySearch = useCallback((searchTerm: string | null | undefined) => {
    const rows = document.querySelectorAll('[data-row-id]');
    rows.forEach(row => {
      const rowId = row.getAttribute('data-row-id');
      if (rowId) {
        row.classList.remove('hidden');
      }
    });
    if (!searchTerm) {
      return;
    }
    rows.forEach(row => {
      const rowId = row.getAttribute('data-row-id');
      const rowData = columnsData && columnsData[0] && columnsData[0].data.find((item: any) => item.id === rowId);
      //console.log('rowData', rowId, rowData, searchTerm);
      if (
        rowData &&
        (rowData.amountTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          rowData.percentTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          String(rowData.glCode)?.includes(searchTerm))
      ) {
        row.classList.remove('hidden');
        //console.log('Found rowData', rowData, searchTerm);
      } else {
        row.classList.add('hidden');
      }
    });
  }, []);

  useEffect(() => {
    applySearch(searchTerm);
  }, [searchTerm, applySearch]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--ks-header-height',
      budgetAvailable && capabilities.budget && filters.budget && filters.actuals
        ? data.periodReporting
          ? '70px'
          : '60px'
        : data.periodReporting
          ? '60px'
          : '50px'
    );
  }, [filters]);

  useEffect(() => {
    if (columnsData && columnsData.length > 0) {
      setColumns(
        columnsData.filter(
          column =>
            column.compoundColumn === false && column.originType !== 'Total' && column.originType !== 'Difference'
        ).length
      );
      const style = createRowStyles(columnsData, searchTerm);
      document.head.appendChild(style);
      return () => {
        document.head.removeChild(style);
      };
    } else {
      setColumns(0);
    }
  }, [columnsData, setColumns]);

  // Layout Draw function, it is memoized to avoid re-renders when scrolling
  const memoizedRenderColumns = useMemo(() => {
    const reverseColumns = actions.reverseColumns;
    const addTotalRow = actions.totalRow;
    const addTotalColumn = capabilities.synthetic && actions.total;
    const addDifference = capabilities.synthetic && actions.difference;

    const { renderedHeaders, renderedColumns } = useColumnRenderer(
      data,
      columnsData || [],
      reverseColumns,
      addTotalRow,
      addTotalColumn,
      addDifference,
      capabilities.liveReport && data.liveReport,
      fixFC,
      fixLC
    );
    const prefix = prefixClass('nt-layout');
    return (
      <>
        <div className={prefix('header-container')}>{renderedHeaders}</div>
        <div id="table-y-scroll" className={prefix('layout-container')}>
          {renderedColumns}
        </div>
      </>
    );
  }, [data, columnsData, fixFC, fixLC, actions]);

  if (!columnsData || columnsData.length === 0) {
    return <div>Incomplete Data or Malformed Data</div>;
  }

  return <>{memoizedRenderColumns}</>;
};
export default NTLayout;
