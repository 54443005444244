import { IconMoneyBudget } from 'components/Icons';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { useQueryState } from 'hooks/useQueryState';
import KSButton from 'presentation/components/KSButton';
import { useState } from 'react';

export default function BookkeerBudgetEmptyState() {
  const [, setModalBudgetOpen] = useQueryState('modalBudgetOpen', false);
  const location = useLocationsStore(state => state.currentLocation);

  const handleCreateBudget = () => {
    setModalBudgetOpen('true');
  };

  return (
    <div className="budgets-layout__placeholder">
      <IconMoneyBudget />

      <p className="mb-4">Currently, there is no budget configured for this location.</p>
      <p className="mb-4">
        Would you like to set up a budget for <b>{location?.name}</b>?
      </p>

      <KSButton label="Create Budget" onClick={handleCreateBudget} />
    </div>
  );
}
