import React, { Profiler, ProfilerOnRenderCallback, useRef } from 'react';

const NTProfiler: React.FC<{ children: React.ReactNode; onComplete?: () => void }> = ({ children, onComplete }) => {
  const hasCompleted = useRef(false);
  const handleRender: ProfilerOnRenderCallback = (id, phase, actualDuration, baseDuration, startTime, commitTime) => {
    //console.log(`Rendering time for ${id}: ${actualDuration.toFixed(2)}ms`);
    if (phase === 'mount' && !hasCompleted.current) {
      hasCompleted.current = true;
      onComplete?.();
    }
    if (phase === 'update' && actualDuration > 1000) {
      console.warn(`Rendering time for ${id} exceeded 1000ms: ${actualDuration.toFixed(2)}ms`);
    }
  };

  return (
    <Profiler id="NewTable" onRender={handleRender}>
      {children}
    </Profiler>
  );
};
export default NTProfiler;
