import { el } from 'date-fns/locale';
import { formatAmountCondensed } from '../../NTCell/ValueFormatters/ValueFormatters';
import { getCSSVariable } from '../../NTMiniMap/NTMiniMapUtils';
import {
  ChartDataColumn,
  ChartGroup,
  ChartProps,
  ChartContext,
  TooltipAreas,
  Measures,
  TooltipZone,
  ChartDataColumnChildren,
} from '../types';
import { colorBlock, generateColorShades } from './chartUtils';

export function drawGridAndLabels(props: ChartContext) {
  const { data: chartData, body, structure, ctx, chartFilters, dimensions, allValuesAreZero } = props;
  const { margin, chartHeight, chartWidth } = body;
  const { yPercentScale, maxTicks, yMoneyScale, zeroLineY, zeroPercentLineY } = structure;

  if (!ctx || !chartData) return;
  const width = chartWidth + margin.left + margin.right;
  const height = chartHeight + margin.top + margin.bottom;
  const { money, percent } = chartFilters;

  // Draw grid lines and labels
  ctx.textAlign = 'right';
  ctx.textBaseline = 'middle';
  ctx.fillStyle = 'black';
  ctx.strokeStyle = 'rgba(0, 0, 0, 0.1)';

  ctx.lineWidth = 1; // Set line width to 1 pixels
  ctx.setLineDash([]); // Set solid line pattern

  const moneyTicks = maxTicks;
  const percentTicks = maxTicks;

  // Money axis (or percent axis if money is not selected)
  if (money || (!money && percent)) {
    for (let i = 0; i < moneyTicks; i++) {
      const value = money
        ? yMoneyScale.min + (i / (moneyTicks - 1)) * (yMoneyScale.max - yMoneyScale.min)
        : yPercentScale.min + (i / (percentTicks - 1)) * (yPercentScale.max - yPercentScale.min);
      const y = money
        ? height - margin.bottom - ((value - yMoneyScale.min) / (yMoneyScale.max - yMoneyScale.min)) * chartHeight
        : height -
          margin.bottom -
          ((value - yPercentScale.min) / (yPercentScale.max - yPercentScale.min)) * chartHeight;

      // Grid line
      ctx.beginPath();
      ctx.moveTo(margin.left, y);
      ctx.lineTo(width - margin.right, y);
      ctx.stroke();

      // Money label (left)
      const yValue = money ? formatAmountCondensed(value, true) : `${(value * 100).toFixed(2)}%`;
      ctx.fillText(yValue, margin.left - 10, y);
    }
  }

  // Percentage axis. Only on the right side if both money and percent are true
  if (percent && money) {
    for (let i = 0; i < percentTicks; i++) {
      const value = yPercentScale.min + (i / (percentTicks - 1)) * (yPercentScale.max - yPercentScale.min);
      const y =
        height - margin.bottom - ((value - yPercentScale.min) / (yPercentScale.max - yPercentScale.min)) * chartHeight;

      // Percentage label (right)
      ctx.textAlign = 'left';
      ctx.fillText(`${(value * 100).toFixed(2)}%`, width - margin.right + 10, y);
    }
  }

  // Draw zero line
  const visibleZeroLine = percent && !money ? zeroPercentLineY : zeroLineY;
  ctx.beginPath();
  ctx.moveTo(margin.left, visibleZeroLine);
  ctx.lineTo(dimensions.width - margin.right, visibleZeroLine);
  ctx.strokeStyle = 'black';
  ctx.stroke();
}

export function drawColumnsBackground(props: ChartContext) {
  const { data: chartData, body, ctx, groups, allValuesAreZero } = props;
  const { margin, chartHeight, groupWidth, groupGap, barGap, barWidth } = body;

  if (!ctx || !chartData) return;

  chartData.columns.forEach((column: ChartDataColumn, index: number) => {
    const x = margin.left + index * (groupWidth + groupGap);
    ctx.fillStyle =
      column.liveReport && !allValuesAreZero
        ? 'rgba(241, 229, 163, 0.15)'
        : groups.length > 1
          ? 'rgba(240, 240, 240, 0.27)'
          : 'rgba(240, 240, 240, 0)';
    ctx.fillRect(x, margin.top, groupWidth, chartHeight);
    groups.forEach((type, typeIndex) => {
      if (typeIndex > 0) {
        const barX = x + typeIndex * (barWidth + barGap);
        ctx.strokeStyle = 'rgba(200, 200, 200, 0.2)';
        ctx.beginPath();
        ctx.setLineDash([3, 3]); // Set dotted line pattern
        ctx.lineWidth = 1; // Set line width to 2 pixels
        ctx.moveTo(barX, margin.top);
        ctx.lineTo(barX, chartHeight);
        ctx.stroke();
      }
    });
  });
}

export function drawXLabels(props: ChartContext) {
  const { data: chartData, body, dimensions, ctx } = props;
  const { margin, groupWidth, groupGap, font, fontSize } = body;
  if (!ctx || !chartData) return;

  const fisrtColumn = chartData.columns[0];
  const labelToCheckWidth =
    chartData.isLocation && !chartData.consolidated
      ? fisrtColumn.name.split(' - ')[0]
      : fisrtColumn.name.split(', ')[0];
  ctx.font = `${fontSize.label}px ${font}`;
  const labelWidth = ctx.measureText(labelToCheckWidth).width;
  const shouldRotateLabels = labelWidth > groupWidth - groupGap;
  const shouldHideAlternateLabels = groupWidth < 25;

  // X-axis labels
  chartData.columns.forEach((column: ChartDataColumn, index: number) => {
    const x = margin.left + index * (groupWidth + groupGap);
    if (!shouldHideAlternateLabels || index % 2 === 0) {
      ctx.fillStyle = column.liveReport ? 'rgba(158,146,20,1)' : 'black';
      ctx.save();
      ctx.font = `${fontSize.label}px ${font}`;
      if (shouldRotateLabels) {
        ctx.translate(x + groupWidth / 2, dimensions.height - margin.bottom + 10);
        ctx.rotate(-Math.PI / 4);
        ctx.textAlign = 'right';
      } else {
        ctx.textAlign = 'center';
        ctx.translate(x + groupWidth / 2, dimensions.height - margin.bottom + 20);
      }
      // When the text fits in  the column, we split the text in two lines
      const [firstLine, secondLine] = column.name.split(chartData.isLocation && !chartData.consolidated ? ' - ' : ', ');
      ctx.fillText(firstLine, 0, 0);
      if (!column.liveReport) ctx.fillStyle = 'grey';
      ctx.fillText(secondLine || '', 0, 12);
      ctx.restore();
    }
  });
}

export function drawNoData({
  context,
  allZero = false,
  isParent = false,
}: {
  context: ChartContext;
  allZero: boolean;
  isParent?: boolean;
}) {
  const { ctx, dimensions, body } = context;
  const { font } = body;
  if (!ctx) return;

  ctx.font = `16px ${font}`;
  ctx.fillStyle = 'grey';
  ctx.textAlign = 'center';
  ctx.fillText(
    allZero
      ? isParent
        ? 'No totals available. Please select a sub-account.'
        : 'There are no data values available for the Period.'
      : 'No data available',
    dimensions.width / 2,
    dimensions.height / 2
  );
}
