import { useCallback, useRef } from 'react';
import { useNTScroll } from '../NTScrollContext';

type DragAxis = 'x' | 'y';

interface DragOptions {
  axis: DragAxis;
  throttleMs?: number;
  isBrowser?: boolean;
  isMiniMap?: boolean;
}

export function useDragHandler({ axis, throttleMs = 16, isBrowser = true, isMiniMap = false }: DragOptions) {
  const { setScrollState } = useNTScroll();
  const dragStateRef = useRef({
    lastUpdate: 0,
    startPos: 0,
    startValue: 0,
    isDragging: false,
  });

  const handleDragStart = useCallback(
    (e: React.MouseEvent, initialValue: number) => {
      e.preventDefault();
      e.stopPropagation();

      const startPos = axis === 'x' ? e.clientX : e.clientY;
      dragStateRef.current = {
        lastUpdate: 0,
        startPos,
        startValue: initialValue,
        isDragging: true,
      };

      const handleDragMove = (moveEvent: MouseEvent) => {
        if (!dragStateRef.current.isDragging) return;

        const now = Date.now();
        if (now - dragStateRef.current.lastUpdate < throttleMs) return;

        const currentPos = axis === 'x' ? moveEvent.clientX : moveEvent.clientY;
        const delta = currentPos - dragStateRef.current.startPos;
        const newValue = dragStateRef.current.startValue + delta;

        // Get all necessary DOM elements
        const headerMiddle = document.getElementById('header-middle');
        const tableMiddle = document.getElementById('table-middle');
        const tableYScroll = document.getElementById('table-y-scroll');
        const tableScroll = axis === 'x' ? tableMiddle : tableYScroll;
        const columnBrowserWindow = document.getElementById('columnBrowserWindow');
        const columnBrowserArea = document.getElementById('columnBrowserArea');
        const miniMapWindow = document.getElementById('miniMapWindow');
        const miniMapArea = document.getElementById('miniMapArea');
        const miniMapWindowArea = document.getElementById('miniMapWindowArea');

        if (!tableMiddle || !tableYScroll || !tableScroll || (isMiniMap && !miniMapArea)) return;

        const scrollProperty = axis === 'x' ? 'scrollLeft' : 'scrollTop';
        const scrollDimension = axis === 'x' ? 'scrollWidth' : 'scrollHeight';
        const visibleDimension = axis === 'x' ? 'offsetWidth' : 'offsetHeight';

        // Calculate scroll properties
        const columnBrowserElemPadding = getComputedStyle(document.documentElement)
          .getPropertyValue('--ks-column-browser-padding')
          .trim();

        const maskedAreaTotal =
          axis === 'x'
            ? (columnBrowserArea?.offsetWidth || 0) - parseInt(columnBrowserElemPadding)
            : miniMapArea?.offsetHeight || 0;

        const visibleAreaOfScroll = tableScroll[visibleDimension] / tableScroll[scrollDimension];
        const horizontalVisibleArea = tableMiddle.offsetWidth / tableMiddle.scrollWidth;
        const maskedArea = maskedAreaTotal * visibleAreaOfScroll;

        // Calculate legal position
        let scrollPosition: number;
        let scrollPercentage: number;

        if (isMiniMap) {
          // For minimap, calculate scroll based on the available scroll area
          const maxScroll = tableScroll[scrollDimension] - tableScroll[visibleDimension];
          const availableDragArea = maskedAreaTotal - maskedArea;
          const legalMax = Math.max(0, Math.min(newValue, availableDragArea));
          scrollPercentage = legalMax / availableDragArea;
          scrollPosition = maxScroll * scrollPercentage;
        } else if (isBrowser) {
          const legalMax = Math.max(0, Math.min(newValue, maskedAreaTotal - maskedArea));
          scrollPercentage = legalMax / maskedAreaTotal;
          scrollPosition = tableScroll[scrollDimension] * scrollPercentage;
        } else {
          const minPosition = tableScroll[scrollDimension] - tableScroll[visibleDimension];
          scrollPosition = Math.max(0, Math.min(newValue, minPosition));
          scrollPercentage = scrollPosition / tableScroll[scrollDimension];
        }

        // Update DOM elements
        if (axis === 'x' && columnBrowserWindow) {
          const browserAreaX = scrollPercentage * maskedAreaTotal + parseInt(columnBrowserElemPadding) / 2;
          columnBrowserWindow.style.width = `${maskedArea}px`;
          columnBrowserWindow.style.left = `${browserAreaX}px`;
          if (headerMiddle) headerMiddle[scrollProperty] = scrollPosition;
        }

        if (isMiniMap && miniMapWindow) {
          // Handle vertical movement
          const browserAreaY = scrollPercentage * (maskedAreaTotal - maskedArea);
          miniMapWindow.style.height = `${maskedArea}px`;
          miniMapWindow.style.top = `${browserAreaY}px`;

          // Always update miniMapWindowArea width based on horizontal scroll
          if (miniMapWindowArea) {
            const miniMapAreaWidth = miniMapWindow.offsetWidth - 16 - 6;
            miniMapWindowArea.style.width = `${miniMapAreaWidth * horizontalVisibleArea}px`;

            // Calculate horizontal position based on current scroll
            const horizontalScrollPercentage =
              tableMiddle.scrollLeft / (tableMiddle.scrollWidth - tableMiddle.offsetWidth);
            const horizontalPosition = (miniMapAreaWidth - 6) * horizontalScrollPercentage + 16 + 4;
            miniMapWindowArea.style.left = `${horizontalPosition}px`;
          }
        }

        // Update scroll position directly
        tableScroll[scrollProperty] = scrollPosition;

        // Update scroll state to maintain consistency
        setScrollState(prevState => ({
          ...prevState,
          [axis === 'x' ? 'horizontalScroll' : 'verticalScroll']: scrollPosition,
          [axis === 'x' ? 'horizontalScrollPercentage' : 'verticalScrollPercentage']: scrollPercentage,
          scrollAreaWidth: tableMiddle.offsetWidth,
          scrollAreaHeight: tableYScroll[scrollDimension],
          visibleAreaOfScrollHorizontal: axis === 'x' ? visibleAreaOfScroll : prevState.visibleAreaOfScrollHorizontal,
          visibleAreaOfScrollVertical: axis === 'y' ? visibleAreaOfScroll : prevState.visibleAreaOfScrollVertical,
          columnBrowserVisible: visibleAreaOfScroll < 1,
          minimapVisible: visibleAreaOfScroll < 1,
        }));

        dragStateRef.current.lastUpdate = now;
      };

      const handleDragEnd = () => {
        // Get final scroll position before cleanup
        const tableMiddle = document.getElementById('table-middle');
        const tableYScroll = document.getElementById('table-y-scroll');
        const tableScroll = axis === 'x' ? tableMiddle : tableYScroll;

        if (tableMiddle && tableYScroll && tableScroll) {
          // One final state update to ensure consistency
          const scrollDimension = axis === 'x' ? 'scrollWidth' : 'scrollHeight';
          const visibleDimension = axis === 'x' ? 'offsetWidth' : 'offsetHeight';
          const scrollProperty = axis === 'x' ? 'scrollLeft' : 'scrollTop';

          const visibleAreaOfScroll = tableScroll[visibleDimension] / tableScroll[scrollDimension];
          const scrollPosition = tableScroll[scrollProperty];
          const scrollPercentage = scrollPosition / tableScroll[scrollDimension];

          setScrollState(prevState => ({
            ...prevState,
            [axis === 'x' ? 'horizontalScroll' : 'verticalScroll']: scrollPosition,
            [axis === 'x' ? 'horizontalScrollPercentage' : 'verticalScrollPercentage']: scrollPercentage,
            scrollAreaWidth: tableMiddle.offsetWidth,
            scrollAreaHeight: tableYScroll[scrollDimension],
            visibleAreaOfScrollHorizontal: axis === 'x' ? visibleAreaOfScroll : prevState.visibleAreaOfScrollHorizontal,
            visibleAreaOfScrollVertical: axis === 'y' ? visibleAreaOfScroll : prevState.visibleAreaOfScrollVertical,
            columnBrowserVisible: visibleAreaOfScroll < 1,
            minimapVisible: visibleAreaOfScroll < 1,
          }));
        }

        dragStateRef.current.isDragging = false;
        document.removeEventListener('mousemove', handleDragMove);
        document.removeEventListener('mouseup', handleDragEnd);
      };

      document.addEventListener('mousemove', handleDragMove);
      document.addEventListener('mouseup', handleDragEnd);
    },
    [axis, throttleMs, isBrowser, isMiniMap, setScrollState]
  );

  return handleDragStart;
}
