import { useQuery } from '@tanstack/react-query';

import { makeGetBudgets } from 'main/factories/usecases/budgets/remote-get-budgets';
import { GetBudgetRequest } from '@hone-automation/common';
import { useActiveReportStore } from 'hooks/useActiveReportStore';

export const useGetBudgets = (budgetRequest: GetBudgetRequest) => {
  return useQuery({
    enabled: !!localStorage.getItem('hone:session'),
    queryKey: ['budgets', budgetRequest.locationId, budgetRequest.timeframe, budgetRequest.startDate],
    queryFn: async () => {
      const service = makeGetBudgets();
      const budgetReport = await service.getBudgets(budgetRequest);
      const nestedBudgetReport = { ...budgetReport } as NestedHoneReport;
      const newDates = nestedBudgetReport.dates.map(item => {
        return { ...item, type: 'Data' };
      });
      const newTransformedData = { ...nestedBudgetReport, dates: newDates };
      useActiveReportStore.setState({ currentReport: newTransformedData });
      return newTransformedData;
    },
    staleTime: 1000 * 60 * 5,
  });
};
