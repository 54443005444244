import { GetBudgetRequest } from '@hone-automation/common';
import Loading from 'components/Loading';
import { useGetBudgets } from 'hooks/budgets';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { useQueryState } from 'hooks/useQueryState';

import './BookkeeperBudgets.scss';

import KSButton from 'presentation/components/KSButton';
import { IconPlus } from 'components/Icons';
import BookkeerBudgetEmptyState from './BookkeerBudgetEmptyState';
import NewTable from 'presentation/components/NewTable';
import Footer from 'presentation/components/Footer/Footer';
import BookkepperBudgetsModal from './BookkeeperBudgetsModal';

export default function BookkeeperBudgets() {
  const currentLocationId = useLocationsStore(state => state.currentLocation?.id);
  const [timeframe] = useQueryState('timeframe', 'Month');
  const [startDate, setStartDate] = useQueryState('startDate', '2024-01-01');
  const [modalBudgetOpen, setModalBudgetOpen] = useQueryState('modalBudgetOpen', false);

  const activeYear = startDate && (startDate as string).split('-')[0];

  const budgetParams: GetBudgetRequest = {
    locationId: String(currentLocationId),
    timeframe: timeframe as any,
    startDate: String(startDate),
  };

  const { isLoading, data: budgetReport } = useGetBudgets(budgetParams);
  if (isLoading) {
    return <Loading />;
  }

  const handleChangeBudgetPeriod = (startDate: string) => {
    setStartDate(startDate);
  };

  const handleCreateBudget = () => {
    setModalBudgetOpen('true');
  };

  const periods = ['2022', '2023', '2024', '2025'];

  return (
    <>
      <div className="budgets-layout">
        <BookkepperBudgetsModal
          isOpen={modalBudgetOpen === 'true'}
          onRequestClose={() => setModalBudgetOpen('false')}
        />

        <div className="budgets-layout__header">
          <div className="budgets-layout__header__periods">
            {periods.map((period, i) => (
              <>
                <KSButton
                  onClick={() => handleChangeBudgetPeriod(`${period}-01-01`)}
                  label={period}
                  color={period === activeYear ? 'active' : 'primary'}
                />
                {i !== periods.length - 1 && <span className="budgets-layout__header__periods_divider" />}
              </>
            ))}
          </div>
          <div className="budgets-layout__header__actions">
            <KSButton onClick={() => handleCreateBudget()} label="New Budget" icon={<IconPlus />} />
          </div>
        </div>
        <div className="budgets-layout__container">
          <div className="budgets-layout__container__content">
            {budgetReport && budgetReport.sections.length > 0 ? (
              <NewTable report={budgetReport as NestedHoneReport} reportType="budget" />
            ) : (
              <BookkeerBudgetEmptyState />
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
