import classNames from 'classnames';
import { useCallback } from 'react';
import NTCell from '../NTCell';
import { useNTContext } from '../NTContext';
import { prefixClass } from 'lib/utils';
import { Amounts } from '../../KSExport/reportNTParser/types';

const NTDrawColumn = ({
  columnIndex,
  columnData,
  liveColumn,
  fixed = false,
  totalColumn = false,
  compoundColumn = false,
}: {
  title?: string | null;
  columnIndex?: number;
  columnData?: any[];
  liveColumn?: boolean;
  fixed?: boolean;
  totalColumn?: boolean;
  compoundColumn?: boolean;
}) => {
  const { columns, setColumns } = useNTContext();
  const prefix = prefixClass('nt-layout-column');
  return columnData ? (
    <div
      className={classNames(prefix(), {
        fixed: fixed,
        'first-column': columnIndex === 0,
        compound: compoundColumn,
        live: liveColumn,
      })}
    >
      {columnData.map((item: any, itemIndex: number) => (
        <NTCell
          key={`${item.id || itemIndex}_${itemIndex}`}
          cellData={item}
          cellID={item.cellID}
          liveColumn={liveColumn}
          rowID={item.id}
          allColumn={columnData}
          totalColumn={totalColumn}
        />
      ))}
    </div>
  ) : (
    <div>xx</div>
  );
};

export default NTDrawColumn;
