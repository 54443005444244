import { formatRangeValues } from 'lib/reportUtils';
import { Amounts } from './types';

export const isDate = (str: string): boolean => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  return dateRegex.test(str);
};

export const isDateBetween = (start: string, end: string, dateToCheck: string): boolean => {
  // Regular expression for date format YYYY-MM-DD
  const dateFormat = /^\d{4}-\d{2}-\d{2}$/;

  // Check if all dates are in the correct format
  if (!dateFormat.test(start) || !dateFormat.test(end) || !dateFormat.test(dateToCheck)) {
    return false;
  }

  // Convert string dates to Date objects
  const startDate = new Date(start);
  const endDate = new Date(end);
  const checkDate = new Date(dateToCheck);

  // Check if the dateToCheck falls between start and end dates
  return checkDate >= startDate && checkDate <= endDate;
};

export const isLiveReport = (report: NestedHoneReport): number => {
  return report.dates.findIndex((date: any) => {
    if (date.end && date.end.startsWith('2')) {
      return new Date(date.end).getTime() > new Date().getTime();
    }
    return false;
  });
};

// Identify if there is a total or difference column from the Report's date values
export const inferColumnType = (
  date: any,
  locationId: string | null,
  locationName: string | null,
  useComputedColumns?: boolean
): { isTotal: boolean; isDifference: boolean } | null => {
  const isTotal =
    date.type === 'Total' || date.start === '' ? true : String(date.start).trim().toLowerCase() == 'total';
  const isDifference = date.type === 'Difference' || String(date.start).trim().toLowerCase() == 'difference';
  const doNotProcess =
    date.type &&
    !date.groupedDates &&
    (!locationId || !locationName) &&
    (!useComputedColumns || (!isDifference && !isTotal));
  return doNotProcess ? null : { isTotal, isDifference };
};

export const reportRange = (report: NestedHoneReport): { start: string; end: string; range: string } => {
  const start = report.dates[0]?.start ?? '';
  const end = report.dates[report.dates.length - 1]?.end ?? '';
  const range = isDate(start)
    ? formatRangeValues(start && end ? `${start} - ${end}` : start, 'KS')
    : start || 'Default Range';
  return { start, end, range };
};

export const buildTitle = (
  report: NestedHoneReport,
  datesRange?: { start: string; end: string; range: string }
): string => {
  return [
    report.type + ` (${report.timeframe})`,
    datesRange && datesRange.range !== datesRange.start
      ? datesRange.range
      : new Date().toLocaleString('en-US', { month: 'short', year: '2-digit' }),
  ].join(' - ');
};

// Utility function to get the data serie for the amounts column
export const series = (data: any[], property: string): any[] =>
  data.map(item => {
    const value = item[property];
    return Array.isArray(value) ? value.length : value;
  });

export const generateFilteredId = (section: string, title: string): string => {
  return section === ''
    ? 'row_' +
        title
          .toLowerCase()
          .replace(/[^\w\s]/gi, '')
          .replace(/\s+/g, '')
          .slice(0, 20)
    : section;
};

export const findDataElementByTitle = (columnData: Amounts[], title: string) => {
  if (!title) return null;
  const filteredTitle = title.replace(/^\([^)]*\)\s*/, '').trim();
  return columnData.find(element => element.title === filteredTitle || element.refTitle === filteredTitle);
};

export const logger = {
  msg: (msg: string, data?: any) => {
    if (window.location.hostname === 'localhost') {
      // eslint-disable-next-line no-console
      console.log(`[LOG] ${msg}`, data || '');
    } else {
      console.warn(`[LOG] ${msg}`, data || '');
    }
  },
  error: (msg: string, data?: any) => {
    if (window.location.hostname === 'localhost') {
      // eslint-disable-next-line no-console
      console.log(`[ERROR] ${msg}`, data || '');
    } else {
      console.warn(`[ERROR] ${msg}`, data || '');
    }
  },
};
