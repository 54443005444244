import { getReportCapabilities } from './capabilities';
import { getCustomLabel } from './periods';
import { Column, TransformedData, Location } from './types';
import { buildTitle, inferColumnType, isLiveReport, reportRange } from './utils';
import { ReportConfiguration } from '@hone-automation/common/lib/Reports';
import { Option } from 'types';
import { logger } from './utils';
import { Location as KsLocation } from '@hone-automation/common';

interface createTransformedDataProps {
  report: NestedHoneReport;
  useComputedColumns?: boolean;
  periodReporting?: boolean;
  currentLocation?: KsLocation;
  reportCFG?: ReportConfiguration;
  periodTitles: Option[] | null;
  budget?: boolean;
}

interface initializeTransformDataProps extends createTransformedDataProps {
  isMultiLocation?: boolean;
  consolidated: boolean;
  periodReporting: boolean;
  isTotal: boolean;
  isDifference: boolean;
}

const initializeTransformData = (props: initializeTransformDataProps): TransformedData | null => {
  const {
    report,
    useComputedColumns,
    periodReporting,
    isMultiLocation,
    currentLocation,
    consolidated,
    isTotal,
    isDifference,
    periodTitles,
    budget,
  } = props;
  if (!report || !currentLocation) return null;

  const currentCapabilities = getReportCapabilities(budget ? 'Budget' : report.type);
  const transformedData: TransformedData = {
    name: buildTitle(report, reportRange(report)),
    timeFrame: report.timeframe,
    type: report.type,
    capabilities: currentCapabilities,
    range: reportRange(report),
    currency: 'USD',
    locationId: currentLocation?.id,
    locationName: currentLocation?.name,
    columns: [],
    renderableColumns: 0,
    locations: [],
    budgetAvailable: false,
    totalColumn: !useComputedColumns || isTotal,
    difference: !useComputedColumns || isDifference,
    liveReport: currentCapabilities.liveReport ? isLiveReport(report) : -1, // --------> add a new property to hold the live column, it should always be the 1st one, but just in case
    multiLocation: isMultiLocation,
    consolidated,
    periodReporting: periodReporting && report.timeframe !== 'Weekly',
    periodTitles,
    utilityData: {
      totalColumn: { type: [{ type: 'Direct' }], data: [], originType: 'Synthetic Total' },
      differenceColumn: { type: [{ type: 'Direct' }], data: [], originType: 'Synthetic Difference' },
      totalRow: [],
    },
  };
  return transformedData;
};

export const createTransformedData = ({
  report,
  useComputedColumns,
  periodReporting = false,
  currentLocation,
  periodTitles,
  budget = false,
}: createTransformedDataProps): TransformedData | null => {
  if (!report || !currentLocation) {
    logger.error('(silent error) NT:createTransformedData: Missing report or currentLocation');
    return null;
  }

  const locations = new Set(report?.dates.map(date => date.location?.id || date.groupedDates).filter(Boolean));
  const isMultiLocation = locations.size > 1;

  const transformedData: TransformedData = report?.dates.reduce((acc: any, date: any, index: number) => {
    const locationId = date.location?.id;
    const locationName = date.location?.name;
    const reportId = date.reportId;
    const consolidated = !!date.groupedDates;

    // Skip this element if there's no location. This case was to solve difference column, but we will probably skip it soon
    const inferColumnTypeResult = inferColumnType(date, locationId, locationName, useComputedColumns);
    if (!inferColumnTypeResult) {
      return acc;
    }
    const isTotal = inferColumnTypeResult.isTotal;
    const isDifference = inferColumnTypeResult.isDifference;

    if (index === 0) {
      acc = initializeTransformData({
        report,
        currentLocation,
        useComputedColumns,
        isMultiLocation,
        consolidated,
        periodReporting,
        isTotal,
        isDifference,
        periodTitles,
        budget,
      });
    } else {
      acc.totalColumn = acc.totalColumn || isTotal;
      if (date.type !== 'Difference' && date.type !== 'Total') acc.renderableColumns++;
    }

    if (date.groupedDates) {
      date.groupedDates.forEach((groupedDate: any) => {
        const locationId = groupedDate.location?.id;
        const locationName = groupedDate.location?.name;
        const reportId = groupedDate.reportId;

        if (locationId && locationName && !acc.locations.some((loc: Location) => loc.id === locationId)) {
          acc.locations.push({ id: locationId, name: locationName, reportId });
        }
      });
    } else if (locationId && locationName && !acc.locations.some((loc: Location) => loc.id === locationId)) {
      acc.locations.push({ id: locationId, name: locationName, reportId });
    }
    // Not explicit type so probably is not a New P&L
    const originType = date.type;
    let columnType = date.type || (date.start && date.end) ? 'Data' : 'Direct';
    if (date.type === 'Difference' || date.type === 'Total') columnType = 'Direct';

    // Fixed column behavior. If the date.start of the report column is Total or the date.start is empty ("Difference") we activate the last column fixed
    const baseName = date.start === '' ? 'Difference' : date.start;

    if (date.type === 'Budget') {
      // Identifying Budget Column if any to prepare the Budget mergee
      acc.budgetAvailable = true;
      const lastColumn = acc.columns[acc.columns.length - 1];
      const newDate = { start: baseName, end: date.end || null };
      if (lastColumn.date.start === newDate.start && lastColumn.date.end === newDate.end) {
        // If budget exists we add it to the types of the last column
        acc.columns[acc.columns.length - 1].type.push({ type: 'Budget', index });
      }
    } else {
      const newColumn: Column = {
        name: isMultiLocation && !consolidated ? locationName : baseName,
        date: { start: baseName, end: date.end || null },
        type: [{ type: columnType, index }],
        data: [],
        total: isTotal,
        compoundColumn: !useComputedColumns && (isTotal || isDifference),
        isLocation: isMultiLocation,
        liveReport: acc.liveReport !== -1 && isLiveReport(report) === index,
        periodReporting: periodReporting,
        periodID: getCustomLabel(periodTitles, { start: baseName, end: date.end }),
        consolidated: consolidated,
        originType,
      };
      acc.columns.push(newColumn);
    }

    return acc;
  }, {});
  return transformedData;
};
