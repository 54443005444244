import { IconAdd, IconDeleteOutline, IconEdit } from 'components/Icons';
import { MetricsReport } from 'domain/models';
import { useBookkeeperReport } from 'hooks/useBookkeeper';

import { useLocationsStore } from 'hooks/useLocationsStore';
import { useEffect } from 'react';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import Modal from 'react-modal';
import { modalStyle } from './BookkeeperMetrics';
import BookkeeperMetricsReportForm from './BookkeeperMetricsReportForm';
import { formatDate } from 'lib/utils';
import { isEmpty } from 'lodash';
import { ConfirmModal } from 'presentation/components';

export default function BookkeeperMetricsReport(): JSX.Element {
  const {
    fetchBookkeeperMetricsReport,
    fetchBookkeeperMetrics,
    bookkeeperMetricsReport,
    loadingBookkeeper,
    showNewMetricModal,
    setShowNewMetric,
    handleMetricReport,
    bookkeeperMetrics,
    metricReport,
    showConfirmModal,
    setShowConfirmModal,
    deleteMetricReport,
  } = useBookkeeperReport(state => state);

  const currentLocationId = useLocationsStore(state => state.currentLocationId);
  useEffect(() => {
    if (currentLocationId) {
      fetchBookkeeperMetricsReport(currentLocationId);
      fetchBookkeeperMetrics(currentLocationId);
    }
  }, [currentLocationId, fetchBookkeeperMetricsReport, fetchBookkeeperMetrics, showNewMetricModal]);

  const handleClickMetric = (metric: MetricsReport) => {
    handleMetricReport(metric);
    setShowNewMetric(true);
  };
  const handleDeleteMetricReport = () => {
    metricReport !== undefined &&
      metricReport?.id &&
      currentLocationId &&
      deleteMetricReport(metricReport.id, currentLocationId);
  };

  const handleClickDeleteMetric = (metric: MetricsReport) => {
    handleMetricReport(metric);
    setShowConfirmModal(true);
  };

  return (
    <div className="bookkeeper-tab-container">
      <Modal id="metrics-report-modal" style={modalStyle} isOpen={showNewMetricModal}>
        <BookkeeperMetricsReportForm />
      </Modal>
      <Modal
        id="confirm-modal-form"
        style={modalStyle}
        isOpen={showConfirmModal}
        onRequestClose={() => setShowConfirmModal(!showConfirmModal)}
      >
        <ConfirmModal
          onClose={() => setShowConfirmModal(!showConfirmModal)}
          onConfirm={handleDeleteMetricReport}
          title={
            <>
              Delete Metric Report <b>{metricReport?.title}</b>
            </>
          }
        >
          <div>Are you sure? Hit confirm to delete</div>
        </ConfirmModal>
      </Modal>

      <button
        className="bookkeeper-metrics-btn"
        title={'update metric'}
        onClick={() => {
          handleMetricReport(undefined);
          setShowNewMetric(true);
        }}
      >
        <IconAdd />
      </button>
      <div className="hone-report-table">
        <Table
          height={700}
          hover
          data={bookkeeperMetricsReport}
          rowHeight={rowData =>
            rowData && isEmpty(rowData?.configuration?.metricConfigIds)
              ? 35
              : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                Number(rowData?.configuration?.metricConfigIds?.length * 35)
          }
          affixHeader
          affixHorizontalScrollbar
          loading={loadingBookkeeper}
        >
          <Column width={40}>
            <HeaderCell>{''}</HeaderCell>
            <Cell>
              {rowData => (
                <div
                  className="validation-report--table-icon"
                  onClick={() => handleClickMetric(rowData as MetricsReport)}
                >
                  <IconEdit />
                </div>
              )}
            </Cell>
          </Column>
          <Column width={40}>
            <HeaderCell>{''}</HeaderCell>
            <Cell>
              {rowData => {
                return (
                  <div
                    className="validation-report--table-icon"
                    onClick={() => handleClickDeleteMetric(rowData as MetricsReport)}
                  >
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    {<IconDeleteOutline width={18} height={18} />}
                  </div>
                );
              }}
            </Cell>
          </Column>
          <Column width={150}>
            <HeaderCell>Type</HeaderCell>
            <Cell>
              {rowData => {
                return <div className="validation-report--table-icon">{rowData.type}</div>;
              }}
            </Cell>
          </Column>
          <Column width={150}>
            <HeaderCell>Title</HeaderCell>
            <Cell>
              {rowData => {
                return <div className="validation-report--table-icon">{rowData.title}</div>;
              }}
            </Cell>
          </Column>
          <Column width={350}>
            <HeaderCell>Metric Config IDs</HeaderCell>
            <Cell>
              {rowData => {
                const metricConfigs =
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  (rowData.configuration?.metricConfigIds && [...rowData.configuration?.metricConfigIds]) || [];
                return (
                  <div className="bookkeeper-metrics-list">
                    {metricConfigs.map((configId: any, idx: number) => (
                      <div key={`${configId}_${idx}`}>
                        {bookkeeperMetrics?.find(metric => metric.id === configId)?.configuration?.title}
                      </div>
                    )) || ''}
                  </div>
                );
              }}
            </Cell>
          </Column>

          <Column width={350}>
            <HeaderCell>Created At</HeaderCell>
            <Cell>
              {rowData => {
                return <div>{rowData?.createdAt && formatDate(rowData?.createdAt, 'MM/dd/yyyy h:mm:ss aaa')}</div>;
              }}
            </Cell>
          </Column>
          <Column width={350}>
            <HeaderCell>Updated At</HeaderCell>
            <Cell>
              {rowData => {
                return <div>{rowData?.updatedAt && formatDate(rowData?.updatedAt, 'MM/dd/yyyy h:mm:ss aaa')}</div>;
              }}
            </Cell>
          </Column>
        </Table>
      </div>
    </div>
  );
}
