import { GenerateRangesRequest, ReportTimeframe } from '@hone-automation/common';
import { formatRangeValues } from 'lib/reportUtils';

import { getCustomPeriodLabel } from 'lib/utils';

import { Option } from 'types';

import { format } from 'date-fns-tz';
import { ReportConfiguration, StartEndDates } from '@hone-automation/common/lib/Reports';
import { generateRanges } from '@hone-automation/common/lib/reportUtil';
import { makeRemoteGetReportsConfig } from 'main/factories/usecases';
import { Location as KsLocation } from '@hone-automation/common';

const reportConfigurationService = makeRemoteGetReportsConfig();

const fiveYearsAgo = format(new Date(new Date().setFullYear(new Date().getFullYear() - 5)), 'yyyy-MM-dd');

const genDateRanges = ({
  selectedTimeFrame,
  limit,
  reportsConfiguration,
  currentLocation,
}: {
  selectedTimeFrame: ReportTimeframe;
  limit: number;
  reportsConfiguration?: ReportConfiguration;
  currentLocation: KsLocation;
}) => {
  const request = Object.assign(
    new GenerateRangesRequest(selectedTimeFrame, currentLocation?.weekStart, reportsConfiguration?.data),
    { limit, start: fiveYearsAgo }
  );
  const newDateRanges: StartEndDates[] = generateRanges(request);

  return newDateRanges;
};

export const periodIndex = ({
  limit,
  currentLocation,
  reportCFG,
}: {
  limit: number;
  currentLocation: KsLocation;
  reportCFG?: ReportConfiguration;
}) => {
  const timeframe = 'Month';
  const newDateRanges: StartEndDates[] = genDateRanges({
    selectedTimeFrame: timeframe as ReportTimeframe,
    limit,
    currentLocation,
    reportsConfiguration: reportCFG,
  });

  const optionsReportOptionsAvailables: Option[] = newDateRanges.map(dateRange => {
    return {
      value: `${dateRange.start} - ${dateRange.end}`,
      customLabel: `${dateRange.name}`,
      label: `${formatRangeValues(`${dateRange.start} - ${dateRange.end}`, timeframe)}`,
    };
  });
  return optionsReportOptionsAvailables;
};

export const getCustomLabel = (optionsReportOptionsAvailables: Option[] | null, date: any): string => {
  if (!optionsReportOptionsAvailables) {
    return '';
  }
  const customLabel = getCustomPeriodLabel(optionsReportOptionsAvailables as Option[], `${date.start} - ${date.end}`);
  return customLabel !== 'undefined' ? customLabel : '';
};
