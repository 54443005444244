import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TransformedData } from '../KSExport/reportNTParser/types';
import { useNTUtilsContext } from './NTUtilsContext';
import { useNTScroll } from './NTScrollContext';

export type cellStates = {
  hover: string | null;
  selected: string | null;
};

const defaultTransformedData: TransformedData = {
  liveReport: -1,
  totalColumn: false,
  difference: false,
  columns: [],
  locationId: null,
  locationName: null,
  timeFrame: 'Weekly',
  renderableColumns: 0,
  name: '',
  type: 'P&L Comparison',
  currency: 'USD',
  capabilities: {
    money: false,
    budget: false,
    percentage: false,
    transactions: false,
    charts: false,
    sparklines: 'none',
    smoothing: false,
    anomalies: false,
    totalRow: false,
    variance: false,
    synthetic: false,
    flaggedTransactions: false,
    liveReport: false,
  },
  utilityData: {
    totalColumn: {
      type: [{ type: 'Data', index: 0 }],
      data: undefined,
      originType: 'Data',
    },
    differenceColumn: { type: [{ type: 'Data', index: 0 }], data: undefined, originType: 'Data' },
  },
  periodTitles: null,
};

const NTSelectionContext = createContext<{
  transformedData: TransformedData;
  setTransformedData: (transformedData: TransformedData | null) => void;
  cellStates: cellStates;
  setCellStates: (cellStates: cellStates) => void;
  isScrolling: boolean;
  setIsScrolling: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelect: (newIndex: string) => void;
  selectRow: (scrollTo: boolean) => void;
}>({
  transformedData: defaultTransformedData,
  setTransformedData: () => {},
  cellStates: {
    hover: null,
    selected: null,
  },
  setCellStates: () => {},
  isScrolling: false,
  setIsScrolling: () => {},
  handleSelect: () => {},
  selectRow: () => {},
});
export const useNTSelectionContext = () => useContext(NTSelectionContext);

export const NTSelectionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { actions, setActions, capabilities } = useNTUtilsContext();
  const { setScrollChange, setRefreshBrowsers, refreshBrowsers } = useNTScroll();
  const [transformedData, setTransformedDataState] = useState<TransformedData>(defaultTransformedData);

  const [cellStates, setCellStatesState] = useState<cellStates>({
    hover: null,
    selected: null,
  });

  const setCellStates = useCallback((newCellStates: cellStates) => {
    setCellStatesState(newCellStates);
  }, []);

  const [isScrolling, setIsScrolling] = useState(false);

  const scrollToSelectedRow = (newIndex: string) => {
    const tableYScroll = document.getElementById('table-y-scroll');
    const selectedRow = document.querySelector(`[data-row-id="${newIndex}"]`);
    //console.log('selectedRow', selectedRow);
    if (tableYScroll && selectedRow) {
      const container = selectedRow.closest('.first-column') || selectedRow.parentElement;
      if (container) {
        ///console.log('container', container, container?.parentElement?.parentElement);
        const containerRect = container.getBoundingClientRect();
        const rowRect = selectedRow.getBoundingClientRect();
        const scrollOffset = rowRect.top - containerRect.top - tableYScroll.offsetHeight / 2;
        setScrollChange({ axis: 'y', amount: scrollOffset });
      }
    }
  };

  const selectRow = (scrollTo: boolean = false) => {
    const elementsSelected = document.querySelectorAll(`[data-row-id="${cellStates.selected}"]`);
    elementsSelected.forEach(el => el.classList.remove('selected'));
    if (cellStates.selected !== null) {
      const elementsNew = document.querySelectorAll(`[data-row-id="${cellStates.selected}"]`);
      elementsNew.forEach(el => el.classList.add('selected'));
      if (scrollTo) scrollToSelectedRow(cellStates.selected);
    }
  };

  const handleSelect = useCallback(
    (newIndex: string) => {
      if (cellStates.selected !== newIndex) {
        const elementsSelected = document.querySelectorAll(`[data-row-id="${cellStates.selected}"]`);
        elementsSelected.forEach(el => el.classList.remove('selected'));
        const elementsNew = document.querySelectorAll(`[data-row-id="${newIndex}"]`);
        elementsNew.forEach(el => el.classList.add('selected'));
        setCellStates({
          ...cellStates,
          selected: newIndex,
        });
        scrollToSelectedRow(newIndex);
      }
    },
    [cellStates, setCellStates]
  );

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!transformedData.columns[0]?.data || !cellStates.selected || actions.trxModal) {
        return;
      }
      const selectedIndex = transformedData.columns[0].data.findIndex(item => item.id === cellStates.selected);
      let newIndex;

      switch (e.key) {
        // We only prevent Default if we are in one of our particular use cases, otherwise we will interfere with the normal key events in the browser
        case 'ArrowUp': {
          e.preventDefault();
          newIndex = selectedIndex > 0 ? selectedIndex - 1 : 0;
          handleSelect(transformedData.columns[0].data[newIndex].id);
          break;
        }
        case 'ArrowDown':
          e.preventDefault();
          newIndex = selectedIndex < transformedData.columns[0].data.length - 1 ? selectedIndex + 1 : selectedIndex;
          handleSelect(transformedData.columns[0].data[newIndex].id);
          break;

        case 'Enter':
          if (capabilities.charts) {
            e.preventDefault();
            setActions({ ...actions, detailsPanel: !actions.detailsPanel });
            setTimeout(() => {
              setRefreshBrowsers(r => !r);
            }, 12);
          }
          break;
        case '$':
          //console.log('money');
          break;
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [transformedData, cellStates, actions, setActions, capabilities.charts]);

  const setTransformedData = useCallback((newData: TransformedData | null) => {
    if (newData !== null) {
      setTransformedDataState(newData);
    }
  }, []);

  const value = useMemo(
    () => ({
      transformedData,
      setTransformedData,
      cellStates,
      setCellStates,
      isScrolling,
      setIsScrolling,
      handleSelect,
      selectRow,
    }),
    [
      cellStates,
      setCellStates,
      isScrolling,
      setIsScrolling,
      transformedData,
      setTransformedData,
      handleSelect,
      selectRow,
    ]
  );

  return <NTSelectionContext.Provider value={value}>{children}</NTSelectionContext.Provider>;
};
