import './NTToolTip.scss';
import { ChartDataColumn } from '../NTChart/types';
import { prefixClass } from 'lib/utils';
import { formatAmount, formatAmountCondensed } from '../NTCell/ValueFormatters/ValueFormatters';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

export type NTTooltipProps = {
  children?: React.ReactNode;
  position: { x: number; y: number };
};

const NTTooltip = ({ position, children }: NTTooltipProps) => {
  const prefix = prefixClass('chart-tooltip');
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [offset, setOffset] = useState({ horizontal: 0, vertical: 0 });

  useEffect(() => {
    if (!tooltipRef.current) return;
    const tooltipElem = tooltipRef.current;
    const computedStyle = getComputedStyle(document.documentElement);
    const arrow = parseInt(computedStyle.getPropertyValue('--ks-tooltip-arrow-size')) || 0;
    const horizontalMargin = tooltipElem.offsetWidth / 2 - arrow / 2;
    const verticalMargin = tooltipElem.offsetHeight + arrow;
    setOffset({ horizontal: horizontalMargin, vertical: verticalMargin });
  }, [tooltipRef, children]);
  if (!children || !position) return null;
  const { x, y } = position;
  return (
    <div
      ref={tooltipRef}
      className={prefix()}
      style={{
        left: `${x - offset.horizontal}px`,
        top: `${y - offset.vertical}px`,
      }}
    >
      {children || <div>Tooltip</div>}
    </div>
  );
};
export default NTTooltip;
