import classNames from 'classnames';
import { prefixClass } from 'lib/utils';
import NTHeader from '../NTHeader';

const NTDrawHeader = ({
  columnIndex,
  fixed = false,
  data,
}: {
  columnIndex: number;
  fixed?: boolean;
  data: { name: string; data: any; type: string } | any;
}) => {
  const prefix = prefixClass('nt-layout-column');
  return (
    <div
      className={classNames(prefix(), {
        fixed: fixed,

        'header-column': true,

        'first-column': columnIndex === 0,
        live: data.liveReport && columnIndex !== 0,
      })}
    >
      <NTHeader key={`${data.name || columnIndex}`} headerData={data} columnID={columnIndex} />
    </div>
  );
};

export default NTDrawHeader;
