import { DataCellState } from 'domain/models';
import { initialDataCellState } from 'hooks/useActiveReportStore';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { Capabilities } from '../KSExport/reportNTParser/types';
import { act } from 'react-dom/test-utils';

type tooltip = {
  updating: boolean;
  tooltip: string | null;
};

export type actions = {
  difference: boolean;
  total: boolean;
  totalRow: boolean;
  constrained: boolean;
  reverseColumns: boolean;
  trxModal: boolean;
  audit: boolean;
  trxModalData: DataCellState;
  detailsPanel: boolean;
};

type vFilters = {
  money: boolean;
  percentage: boolean;
  transactions: boolean;
  actuals: boolean;
  budget: boolean;
  variance: boolean;
  oneUnit: boolean;
  bigHeader: boolean;
};

type trxModalProps = {
  modalOpen: boolean;
  audit: null;
  dataRows: [];
  date: {
    start: string;
    end: string;
  };
  title: string;
  parent: string;
  total: number;
  loading: boolean;
  hasDataRowsOrAudit: boolean;
};

const NTUtilsContext = createContext<{
  tooltip: tooltip;
  setTooltip: (tooltip: tooltip) => Promise<void>;
  actions: actions;
  setActions: (actions: actions) => void;
  capabilities: Capabilities;
  setCapabilities: (capabilities: Capabilities) => void;
  vFilters: vFilters;
  setVFilters: (vFilters: vFilters) => Promise<void>;
}>({
  tooltip: {
    updating: false,
    tooltip: '',
  },
  setTooltip: async () => {},

  actions: {
    difference: false,
    total: false,
    totalRow: false,
    constrained: true,
    reverseColumns: false,
    trxModal: false,
    trxModalData: initialDataCellState,
    audit: false,
    detailsPanel: false,
  },
  setActions: () => {},

  capabilities: {
    anomalies: false,
    budget: false,
    charts: false,
    money: true,
    percentage: false,
    smoothing: false,
    sparklines: 'none',
    transactions: false,
    totalRow: false,
    variance: false,
    synthetic: false,
    flaggedTransactions: false,
    liveReport: false,
  },
  setCapabilities: () => {},

  vFilters: {
    money: true,
    percentage: false,
    transactions: false,
    actuals: true,
    budget: false,
    variance: false,
    oneUnit: false,
    bigHeader: false,
  },
  setVFilters: async () => {},
});
export const useNTUtilsContext = () => useContext(NTUtilsContext);

export const NTUtilsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tooltip, setTooltip] = useState<tooltip>({
    updating: false,
    tooltip: '',
  });

  const setTooltipAsync = async (newTooltip: tooltip) => {
    setTooltip(newTooltip);
  };

  const [actions, setActions] = useState<actions>({
    difference: false,
    total: false,
    totalRow: false,
    constrained: true,
    reverseColumns: false,
    trxModal: false,
    trxModalData: initialDataCellState,
    audit: false,
    detailsPanel: false,
  });

  const [capabilities, setCapabilities] = useState<Capabilities>({
    anomalies: false,
    budget: false,
    charts: false,
    money: true,
    percentage: false,
    smoothing: false,
    sparklines: 'none',
    transactions: false,
    totalRow: false,
    variance: false,
    synthetic: false,
    flaggedTransactions: false,
    liveReport: false,
  });

  const [vFilters, setVFilters] = useState<vFilters>({
    money: true,
    percentage: false,
    transactions: false,
    actuals: true,
    budget: false,
    variance: false,
    oneUnit: false,
    bigHeader: false,
  });

  const setVFiltersAsync = async (newVFilters: vFilters) => {
    setVFilters(newVFilters);
  };

  const contextValue = useMemo(
    () => ({
      tooltip,
      setTooltip: setTooltipAsync,
      actions,
      setActions,
      capabilities,
      setCapabilities,
      vFilters,
      setVFilters: setVFiltersAsync,
    }),
    [tooltip, actions]
  );

  return <NTUtilsContext.Provider value={contextValue}>{children}</NTUtilsContext.Provider>;
};
